.comp__messages {
    margin: 0;
    padding: 0;
    list-style: none;

    .message {
        text-align: center;
        background: $color-yellow;
        font-size: .875rem;
        color: white;
        font-weight: 600;
        padding: .75rem 0;

        &.error {
            background: $color-red;
        }
    }
}
