$color-yellow: #FCB800;
$color-dgreen: #13463F;
$color-mgreen: #2C6E65;
$color-green: #8ABB2A;
$color-conversion: #5A29BB;
$color-lgreen: #DFF3B8;
$color-lpurple: #ffe2f1;
$color-purple: #9A125A;
$color-red: #FF3333;
$color-dgray: #606060;
$color-gray: #B5B5B5;
$color-lgray: #E3E5E7;
$color-mgray: #ADADAD;
$color-dblue: #253D4E;
$color-grayBlue: #496375;
$color-orange: #E86740;
$color-lorange: #E86740;
$color-brown: #7D4137;
$color-mbrown: #4E3525;
$color-blck: #121212;
$color-secondary-blck: #191919;
$color-blck-text: #1e1e1e;
$color-dgrey: #4D4D4D;
$color-mgrey: #717171;

$color-bggray: #8B8B8B;
$color-bggraylight: #F3F3F3;
$color-white: #fff;
