.section.news {
    .headline {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        a {
            color: $color-gray;
            font-family: $fontFamilyTitles;
            font-weight: 500;
            letter-spacing: .01em;

            &:hover {
                color: $color-green;
            }
        }
    }

    .newList {
        display: grid;
        gap: 1rem;

        .newItem {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            color: $color-grayBlue;
            text-decoration: none;
            margin-bottom: 1rem;

            .cover {
                position: relative;
                height: 20vh;
                min-height: 11rem;
                border-radius: 12px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                .arrow {
                    position: absolute;
                    bottom: .5rem;
                    right: .5rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 3rem;
                    height: 3rem;
                    z-index: 1;

                    &:before {
                        content: '';
                        position: absolute;
                        inset: 0;
                        background: $color-lgray;
                        border: 4px solid white;
                        border-radius: 50%;
                        transition: .25s $defaultTransition;
                        will-change: transform;
                        z-index: -1;
                    }

                    svg {
                        stroke: $color-dgreen;
                    }
                }
            }

            .content {
                max-width: 82%;
                margin: 1rem 0;

                .date {
                    font-size: .8rem;
                    font-family: $fontFamilyTitles;
                    color: $color-grayBlue;
                    font-weight: 500;
                }

                .title {
                    font-size: .975rem;
                    min-height: 2.4rem;
                    font-weight: 700;
                    letter-spacing: -.01em;
                    line-height: 1.2;
                    color: $color-dblue;
                    text-transform: none;
                    margin: .4rem 0 .2rem 0;
                }

                .perex {
                    font-size: 1rem;
                }
            }

            &:hover .cover .arrow::before {
                background: $color-lgreen;
                border-color: $color-lgreen;
                border-width: 8px;
                transform: scale(1.1);
            }
        }
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        gap: .5em;
        font-family: $fontFamilyTitles;

        .endless_page_current.active {
            font-size: .875rem;
            color: $color-dblue;
            align-self: flex-end;
            margin-bottom: .125rem;
        }

        .endless_separator,
        .endless_page_link {
            color: #6C7880;
            font-weight: 500;
            text-decoration: none;
            line-height: 1;
            transition: .25s $defaultTransition;
            padding: .25rem;

            svg {
                stroke: #6C7880;
                margin-top: -.25em;
            }

            &:hover {
                color: $color-green;

                svg {
                    stroke: $color-green;
                 }
            }

            &.active {
                color: $color-dgreen;
                font-weight: 700;
            }
        }
    }

    @media (min-width: 500px) {
        .newList {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media (min-width: 768px) {
        .headline {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

            h2 {
                margin: 0;
            }
        }
    }

    @media (min-width: 992px) {
        .newList {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}
