.section.banners {
    position: relative;

    .sliderWrapper {
        margin: 0 calc(-5vw - var(--bs-gutter-x) * .5);
        overflow: hidden;

        .slider {
            display: flex;
            gap: 1rem;
            padding: 0 calc(5vw + var(--bs-gutter-x) * .5) 1rem;
            overflow: auto;
            max-width: 100%;

            .slick-slide {
                margin: 0 8px;
            }

            .slick-list {
                border-radius: 1rem 0 0 1rem;
                margin-right: calc(-5vw - var(--bs-gutter-x) * .5);
            }
        }
    }

    .banner {
        position: relative;
        height: 57vh;
        min-height: 34rem;
        max-height: 39rem;
        background: #bfbfbf;
        border-radius: 1rem;
        overflow: hidden;

        a {
            &:hover {
                .btn {
                    &::before {
                        transform: scale(1.1);
                    }
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .content {
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            z-index: 1;
            padding: 2rem 1.5rem;

            &>p:first-child {
                margin: 0;

                i,
                em {
                    display: block;
                    color: $color-purple;
                    font-size: 1.375rem;
                    font-weight: 700;
                    font-style: normal;
                    margin-bottom: .75em;
                }
            }

            h2 {
                color: white;
                font-family: $fontFamilySubtitles;
                font-size: 4rem;
                font-size: clamp(2.375rem, 3.75vw, 4rem);
                font-weight: 700;
                line-height: 1;
                letter-spacing: -.01em;

                strong {
                    font-weight: 900;
                    font-size: 112%;
                }
            }

            p {
                max-width: 25em;
                font-size: 1.25rem;
                font-weight: 600;
                margin: 1rem 0 0;
            }

            .btn {
                margin-top: auto;
            }

            .percents {
                display: none;
            }

            &.light {
                h2 {
                    margin-top: .3em;
                }
            }
        }

        &.lorange {
            background: $color-lorange;
        }

        &.brown {
            background: $color-brown;
        }

        &.small {
            height: 35vh;
            min-height: 25rem;
            max-height: 27rem;

            img {
                display: block;
                width: auto;
                object-fit: initial;
                margin-left: auto;
                opacity: .25;
            }

            .content {
                padding: 3.5rem 3rem;

                h2 {
                    color: white;
                    font-size: clamp(1.5rem, 2.45vw, 3.5rem);
                    line-height: 1.15;
                    max-width: 10em;

                    strong {
                        font-size: 100%;
                    }
                }

                p {
                    color: white;
                    font-size: 1.25rem;
                    line-height: 1.5;
                    max-width: 25em;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .nav-controls {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
        margin: 0 calc(12px - 1.5rem);

        .nav-control {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            width: 3rem;
            height: 3rem;
            transform: scale(.25);
            opacity: 0;
            transition: .4s $defaultTransition;
            z-index: 1;

            svg {
                stroke: $color-dgreen;
            }

            &.enabled {
                pointer-events: all;
                opacity: 1;
                transform: scale(1);
            }

            &:before {
                content: '';
                position: absolute;
                inset: 0;
                background: #e5e5e5;
                border: 4px solid #f2f3f5;
                border-radius: 50%;
                transition: .25s $defaultTransition;
                will-change: transform;
                z-index: -1;
            }

            &:hover::before {
                background: $color-lgreen;
                border-color: $color-lgreen;
                border-width: 8px;
                transform: scale(1.1);
            }
        }
    }

    @media (max-width: 991.98px) {
        .sliderWrapper {
            .slider {
                padding: 0;
                margin: 0 calc(5vw + var(--bs-gutter-x) * .5) 1rem;
                overflow: hidden;
                .slick-list {
                    margin-right: 0;
                }
            }
        }

        .nav-controls {
            margin: 0 calc(12px - 1.2rem);

            .nav-control {
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    @media (max-width: 566.98px) {
        .sliderWrapper {
            margin: 0 calc(-2vw - var(--bs-gutter-x) * .5);
            .slider {
                margin: 0 calc(1.5vw + var(--bs-gutter-x) * .5) 1rem;
            }
        }
    }

    @media (min-width: 992px) {
        .sliderWrapper {
            margin: 0;
            overflow: auto;

            .slider {
                padding: 0;

                .slick-list {
                    margin: 0;
                }
            }
        }

        .banner {
            .percents {
                position: absolute;
                top: 2rem;
                right: 1rem;
                display: flex;
                flex-direction: column;

                .percent {
                    position: relative;
                    color: #9E6429;
                    font-size: 7rem;
                    font-weight: 900;
                    line-height: 1;
                    letter-spacing: -0.02em;
                    transform: matrix(0.97, 0.26, -0.26, 0.97, 0, 0);

                    &.big {
                        color: #7D4137;
                        font-size: 9rem;
                    }

                    &+.percent {
                        right: 5rem;
                    }
                }
            }

            .content {
                padding: 4rem 4.5rem;

                &.light {

                    h2 {
                        font-size: clamp(2rem, 3.85vw, 3.5rem);
                        opacity: 1;
                        margin-top: .25em;
                    }

                    p {
                        color: white;
                        max-width: none;
                        margin: 1rem 0 0;
                    }
                }
            }

            img {
                opacity: 1;
            }

            &.small {
                .content {
                    padding: 3.5rem 3rem;
                }
            }
        }
    }
}
