.section.highlights {
    .highlightList {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;

        .highlightItem {
            display: flex;
            flex-direction: column-reverse;
            justify-content: flex-end;
            flex: 1;
            background: white;
            border-radius: 14px;
            padding: 2rem 2.5rem 1.5rem;

            .content {
                color: $color-dgreen;

                h3 {
                    min-height: 3.5rem;
                    font-size: 1.25rem;
                    font-weight: 700;
                    font-family: $fontFamilyTitles;
                    padding: .75rem 0 .5rem;
                    margin: 0;
                }

                p {
                    font-size: 1rem;
                    padding-right: 1.5rem;
                    margin: 0;
                }
            }

            .ico {
                display: flex;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                width: 4rem;
                height: 4rem;
                border-radius: 12px;
                background: $color-lgray;
            }
        }
    }

    @media (min-width: 992px) {
        .highlightList {
            flex-direction: row;

            .highlightItem {
                .content {
                    h3 {
                        min-height: 0;
                        padding-top: 1em;
                    }
                }
            }
        }
    }

    @media (min-width: 1680px) {
        .highlightList {
            .highlightItem {
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                min-height: 16vh;

                .content {
                    h3 {
                        padding: 0 2rem .5rem 0;
                    }

                    p {
                        padding-right: 3rem;
                    }
                }
            }
        }
    }
}
