.section.products {
    .headline {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        a {
            color: $color-gray;
            font-family: $fontFamilyTitles;
            font-weight: 500;
            letter-spacing: .01em;

            &:hover {
                color: $color-green;
            }
        }
    }

    .productList {
        display: grid;
        gap: 1rem;

        &.withBannerItem {
            .bannerItem {
                max-height:500px;
            }
        }

        .bannerItem {
            position: relative;
            max-width: none;
            background: #bfbfbf;
            border-radius: 1rem;
            overflow: hidden;
            padding: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center bottom;
            }

            .content {
                position: absolute;
                inset: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 2rem 1.5rem;
                z-index: 1;

                em {
                    display: block;
                    color: $color-purple;
                    font-size: 1.375rem;
                    font-weight: 700;
                    font-style: normal;
                    margin-bottom: .25em;
                }

                h2 {
                    color: white;
                    font-family: $fontFamilySubtitles;
                    font-size: clamp(2.25rem, 1.75vw, 3rem);
                    font-weight: 700;
                    line-height: 1;
                    letter-spacing: -.01em;

                    strong {
                        font-weight: 900;
                    }
                }
            }
        }
    }

    @media (min-width: 500px) {
        .productList {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: 768px) {
        .headline {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

            h2 {
                margin: 0;
            }
        }

        .productList {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    @media (min-width: 1200px) and (max-width: 1679.98px) {
        .productList.withBannerItem {
            grid-template-columns: repeat(5, 1fr);
            .bannerItem {
                .content {
                    padding: 1.5rem 1rem;
                    h2 {
                        font-size: 2rem;
                    }
                }
            }

            .comp__eshopItem:nth-child(6) {
                display: none;
            }
        }
    }

    @media (min-width: 1680px) {
        .productList {
            grid-template-columns: repeat(6, 1fr);

            &.withBannerItem {
                grid-template-columns: repeat(60, 1fr);

                .bannerItem {
                    grid-column: span 15;
                }

                .comp__eshopItem {
                    grid-column: span 9;
                }
            }

            .bannerItem {
                .content {
                    padding: 2.5rem 2rem;
                }
            }
        }
    }
}
