.comp__eshopItem {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: white;
    border: 2px solid #e5e5e5;
    border-radius: 17px;
    overflow: hidden;
    padding: 1rem .75rem;
    transition: border-color .4s $defaultTransition;

    .tags {
        position: absolute;
        top: 1rem;
        left: .75rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    .favorite {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;

        &.fav-on {
            svg {
                fill: $color-green;
            }
        }

        svg {
            transition: .25s $defaultTransition;
            fill: none;
            stroke: $color-green;
            stroke-width: 1.5;
            overflow: visible;
        }

        &:hover {
            svg {
                fill: $color-green;
                transform: scale(1.1);
                stroke-width: 4;
            }
        }
    }

    .addToList {
        position: absolute;
        right: 1rem;
        top: 1rem;

        .comp__inputCheckbox {
            position: relative;
            flex-direction: row-reverse;
            z-index: 1;

            .input {
                width: 1rem;
                height: 1rem;
                background: $color-bggraylight;

                &:before {
                    content: "";
                    position: absolute;
                    inset: -.5rem;
                    background: $color-mgray;
                    border-radius: 6px;
                    transition: background-color .4s $defaultTransition;
                    z-index: -1;
                    opacity: .25;
                }
            }

            .label {
                font-size: .813rem;
                color: $color-green;
                transition: opacity .25s $defaultTransition;
                margin-right: .5rem;
            }

            input[type="checkbox"]:checked+.input {
                background-color: $color-dblue;

                &:before {
                    background: $color-green;
                }

                &:after {
                    opacity: 1;
                }

                & + .label {
                    opacity: 0;
                }
            }

            &:hover {
                .input:before {
                    background: $color-green;
                }
            }
        }
    }

    .cover {
        height: 12rem;
        margin: -1rem -.75rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            padding: 2.2rem;
        }
    }

    .content {
        .properties {
            display: flex;
            gap: .25rem;
            margin-top: 1rem;

            .property {
                display: inline-flex;
                background: $color-gray;
                font-size: .75rem;
                color: white;
                border-radius: 15px;
                font-weight: 500;
                letter-spacing: -.01em;
                padding: .125rem 1rem;
            }
        }

        .category {
            font-size: .75rem;
            color: $color-grayBlue;
            font-family: $fontFamilyTitles;
            font-weight: 500;
            letter-spacing: -.01em;
            margin: .75rem 0 .25rem;

            svg {
                position: relative;
                top: -1px;
            }
        }

        .title {
            display: flex;
            font-weight: 700;
            color: $color-dblue;
            letter-spacing: -.01em;
            text-decoration: none;
            margin-bottom: .5rem;
        }

        .rating {
            display: flex;
            margin-bottom: .5rem;

            .stars {
                line-height: 0;

                svg {
                    width: .875rem;
                    height: auto;
                    fill: #bcbcbc;
                }

                @for $i from 0 through 5 {
                    &[data-rating^="#{$i}"] {
                        svg:nth-child(-n + #{$i}) {
                            fill: $color-yellow;
                        }
                    }

                    &[data-rating="#{$i}.5"] {
                        svg:nth-child(#{$i + 1}) .half {
                            fill: $color-yellow;
                        }
                    }
                }
            }

            .reviews {
                font-size: .75rem;
                color: $color-gray;
                font-family: $fontFamilyTitles;
                font-weight: 500;
                letter-spacing: -.01em;
                margin-left: .5rem;
            }
        }

        .manufacturer {
            font-size: .875rem;
            font-family: $fontFamilyTitles;
            color: $color-gray;

            strong {
                color: $color-green;
            }
        }
    }

    .master {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1.5rem;
        margin-top: auto;

        .price {
            display: flex;
            flex-direction: column;
            font-size: 1.125rem;
            line-height: 1;
            font-weight: 700;
            color: $color-green;
            letter-spacing: -.01em;

            s {
                color: $color-mgray;
                font-size: .875rem;
                font-weight: 700;
            }
        }

        .btn {
            svg {
                width: 1rem;
                height: auto;
            }

            &.btn--text {
                svg {
                    width: .75rem;
                }
            }
        }
    }

    &.eshopItem--row {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: 100%;
        margin-bottom: 1rem;

        .cover {
            grid-row: span 2;
            height: 15rem;
            margin-right: 0;
            padding: .5rem;

            img {
                padding: 0.75rem;
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            grid-column: span 2;
        }

        .master {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 0;
        }
    }

    &:hover {
        border-color: #bcdba8;
    }

    @media (min-width: 992px) {
        padding: 1.25rem .75rem;

        .tags {
            top: 1.5rem;
            left: 1.25rem;
        }

        .master {
            .price {
                font-size: 1.25rem;
            }
        }

        @media (max-width: 1439.98px) {
            .content {
                .title {
                    font-size: .875rem;
                }
            }
            .master {
                .price {
                    font-size: 1.125rem;
                }

                .btn.btn--small {
                    min-width: 0;
                }
            }
        }
    }

    @media (min-width: 1680px) {
        padding: 1.5rem 1.25rem;

        .cover {
            height: 15rem;
            margin: -1.5rem -1.25rem;;
        }

        .master {
            .price {
                font-size: 1rem;
            }
        }
    }

    @media (min-width: 1920px) {
        padding: 1.75rem 1.5rem;

        .cover {
            height: 18rem;
            margin: -2rem -1.5rem;
        }

        .tags {
            top: 1.75rem;
            left: 1.5rem;
        }

        .master {
            .price {
                font-size: 1.25rem;
            }
        }
    }
}
