.section.manufacturersList {
    .headline {

        h1 {
            margin-bottom: 2rem;
        }
    }

    .manufacturers {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: .5rem;
        max-width: 75rem;
        margin: 3.75rem auto;

        .item {
            display: inline-flex;
            align-items: center;
            background: #F3F3F3;
            border-radius: 15px;
            font-size: 1rem;
            font-weight: bold;
            text-decoration: none;
            padding: .75rem 1.5rem;
            transition: .3s $defaultTransition;

            .logo {
                display: inline-block;
                width: 3.75rem;
                height: 3.75rem;
                margin-right: 1rem;

                img {
                    height: 100%;
                    width: 100%;
                    max-width: 100%;
                    object-fit: contain;
                }
            }

            &:hover {
                background: $color-lgreen;
                transform: scale(1.025);
            }
        }
    }

    @media (min-width: 500px) {
        .manufacturers {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: 768px) {
        .headline {
            text-align: center;
        }
    }

    @media (min-width: 992px) {
        .manufacturers {
            grid-template-columns: repeat(3, 1fr);

            .item {
                font-size: 1.125rem;
                padding: 1rem 2rem;
            }
        }
    }
}
