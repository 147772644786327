.section.productDetail {
    .eshopItemDetailLayout {
        display: grid;
        gap: 2.5rem;

        .contentBox.main {
            order: -1;
        }
    }

    .mainImg {
        a {
            display: flex;
            height: 50vh;
            background: white;
            border-radius: 17px;
            overflow: hidden;

            picture {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                img {
                    width: 75%;
                    max-height: 75%;
                    object-fit: contain;
                }
            }
        }

        .favorite {
            position: absolute;
            right: 1rem;
            top: 1rem;
            height: auto;
            padding: .5rem;
            cursor: pointer;
            z-index: 1;

            &.fav-on {
                svg {
                    fill: $color-green;
                }
            }

            svg {
                transition: .25s $defaultTransition;
                fill: none;
                stroke: $color-green;
                stroke-width: 1.5;
                overflow: visible;
            }

            &:hover {
                svg {
                    fill: $color-green;
                    transform: scale(1.1);
                    stroke-width: 4;
                }
            }
        }
    }

    .miniImg {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: .25rem;
        margin-top: 1rem;

        a {
            position: relative;
            display: block;
            width: 100%;
            background: white;
            border: 1px solid rgba(152, 153, 159, .15);
            border-radius: 8px;
            overflow: hidden;
            padding-top: 100%;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                padding: .25rem;
                border-radius: 12px;
            }
        }
    }

    .contentBox {
        .properties {
            display: flex;
            flex-wrap: wrap;
            gap: .25rem;
        }

        .top-desc {
            .main-desc {
                ul {
                    list-style-type: none;
                    padding-left: 0;
                    margin-bottom: 0;

                    li {
                        padding: .4rem 0;
                        position: relative;
                        padding-left: 40px;

                        &::before {
                            position: absolute;
                            top: .35em;
                            left: 0;
                            content: url(../img/svg/ico-plantheart.svg);
                            width: 30px;
                            height: auto;
                        }
                    }
                }

                ul + p {
                    margin-top: 2.3rem;
                }

                &.perex {
                    font-size: 1.125rem;
                    font-weight: 600;
                    letter-spacing: .01em;
                    margin-top: .3rem;
                }
            }
        }

        .item-name h1 {
            font-size: 2.25rem;
            letter-spacing: -.01em;
            margin: .25em 0 0;
        }

        .rating {
            display: flex;
            margin-bottom: .5rem;

            .stars {
                line-height: 0;

                svg {
                    width: .875rem;
                    height: auto;
                    fill: #bcbcbc;
                }

                @for $i from 0 through 5 {
                    &[data-rating^="#{$i}"] {
                        svg:nth-child(-n + #{$i}) {
                            fill: $color-yellow;
                        }
                    }

                    &[data-rating="#{$i}.5"] {
                        svg:nth-child(#{$i + 1}) .half {
                            fill: $color-yellow;
                        }
                    }
                }
            }

            .reviews {
                font-size: .75rem;
                color: $color-gray;
                font-family: $fontFamilyTitles;
                font-weight: 500;
                letter-spacing: -.01em;
                margin-left: .5rem;
            }
        }

        .manufacturer {
            font-size: .875rem;
            margin-top: .75rem;

            strong {
                font-family: $fontFamilyTitles;
                color: $color-green;
            }
        }
        .MainProductBox {
            span.small {
                font-size: 1rem;
                font-weight: bold;
                color: $color-dblue;
                display: block;
            }

            .price-box {
                font-family: $fontFamilyTitles;
                font-size: 2.25rem;
                font-weight: 700;
                color: $color-green;
                letter-spacing: -.01em;

                .price-wo {
                    color: $color-mgray;
                    font-size: 1rem;
                    font-weight: 700;
                    margin-left: .3em;
                }

                .original-price {
                    color: $color-mgray;
                    font-size: 1rem;
                    font-weight: 400;
                }
            }

            .lowestPrice {
                font-size: .75rem;
                font-weight: 500;
                letter-spacing: -.01em;
                margin: .75rem 0 .25rem;
            }

            .buy-section {
                display: flex;
                height: 4rem;
                border-radius: 6px;
                overflow: hidden;

                .quantityCounter {
                    display: flex;
                    gap: 2px;

                    .count {
                        width: 4rem;
                        height: 100%;
                        color: white;
                        font-weight: 700;
                        font-size: 1.25rem;
                        text-align: center;
                        background: $color-dblue;
                        border: none;
                    }

                    .controls {
                        display: flex;
                        flex-direction: column;
                        gap: 2px;

                        .changer {
                            width: 1rem;
                            flex: 1;
                            color: white;
                            font-size: .875rem;
                            text-align: center;
                            text-decoration: none;
                            line-height: 1.9rem;
                            font-weight: 500;
                            cursor: pointer;
                            background: $color-dblue;
                            user-select: none;
                            transition: background-color .25s $defaultTransition;

                            &:hover {
                                background: $color-green;
                            }
                        }
                    }
                }

                .buy-cont {
                    flex: 0 0 auto;

                    .btn {
                        height: 100%;
                        min-width: 15rem;

                        &:before {
                            transform-origin: center left;
                        }
                    }
                }
            }

            .stock {
                font-size: .938rem;
                font-family: $fontFamilyTitles;
                font-weight: 700;
                margin: 1.25rem 0 .25rem;

                &>span {
                    display: flex;
                    align-items: center;
                    gap: 0.45rem;
                }

                .stock-no-warn {
                    color: $color-green;
                }

                .notfound {
                    color: $color-orange;
                }
            }

            .eshop-item-watchdog {
                display: flex;
                align-self: center;
                font-size: .75rem;
                gap: 0.45rem;
                line-height: 1.5;

                svg {
                    stroke: currentColor;
                }
            }
        }

        .tables {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-top: 2rem;

            .nutritionInfo {
                flex: 1;

                .nutTable {
                    display: flex;
                    flex-direction: column;

                    .nutRow {
                        display: flex;
                        flex-direction: row;
                        color: $color-dblue;
                        background: rgba($color: $color-dblue, $alpha: .15);
                        font-size: .875rem;
                        font-weight: 500;
                        justify-content: flex-start;
                        align-items: center;
                        padding-top: .3rem;
                        padding-bottom: .3rem;
                        min-height: 1.875rem;

                        .nutName {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding-left: .875rem;

                            &.offset {
                                padding-left: 1.5rem;
                            }
                        }

                        .nutValue {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            padding-right: .75rem;
                        }

                        &.energy {
                            color: $color-white;
                            background: rgba($color: $color-dblue, $alpha: .4);
                            font-weight: 700;
                            padding-top: .3rem;
                            padding-bottom: .3rem;
                            min-height: 3.125rem;
                        }

                        &.child {
                            flex: 1 0 auto;
                            align-items: flex-start;
                            background: $color-white;

                            .nutName {
                                display: block;
                                padding-right: .875rem;
                            }
                        }
                    }
                }

            }
        }

        .ingredientsInfo {
            font-size: .938rem;
            line-height: 1.5;
            letter-spacing: .01em;
            padding: 1rem 0;
            margin-top: 1.5rem;

            p {
                &.h6 {
                    font-size: 100%;
                    font-weight: 700;
                }

                i {
                    font-size: .75rem;
                }
            }
        }
    }

    @media (min-width: 768px) {
        .miniImg {
            grid-template-columns: repeat(8, 1fr);
        }

        .contentBox {
            .tables {
                flex-direction: row;
                gap: 2px;
            }
        }
    }

    @media (min-width: 1024px) {
        .eshopItemDetailLayout {
            grid-template-columns: 1fr 1fr;
            gap: 1rem 3rem;

            .imagesBox {
                grid-row: span 3;
            }

            .contentBox.main {
                order: 0;
            }
        }

        .contentBox {
            padding-right: clamp(2.25rem, 2vw, 6rem);

            .tables {
                flex-direction: column;
                gap: 1rem;
            }

            .item-name h1 {
                max-width: 75%;
            }
        }

        .mainImg {
            a {
                height: 60vh;
            }
        }
    }

    @media (min-width: 1200px) {
        .eshopItemDetailLayout {
            column-gap: 4rem;
        }

        .mainImg {
            a {
                height: 75vh;
            }
        }

        .miniImg {
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            max-width: calc(100% - 2rem);
            gap: .5rem;
        }
    }

    @media (min-width: 1440px) {
        .contentBox {
            .tables {
                flex-direction: row;
                gap: 2px;
            }
        }
    }

    @media (min-width: 1680px) {
        .eshopItemDetailLayout {
            column-gap: 5rem;
        }

        .miniImg {
            gap: 1rem;
        }
    }

    hr {
        margin-top: 3rem;
    }

    .mainDescription {
        padding: 3rem 0;

        h2 {
            font-size: 2.25rem;
            margin-bottom: .75em;
        }
    }
}
