.comp__modal {
    .modal-content {
        border: none;

        .modal-header {
            background: $color-dgreen;
            border: none;
            padding: 1.75rem 2rem;

            .modal-title {
                color: white;
            }

            .btn-close {
                color: white;
                font-size: 1.75rem;
                background: none;
                margin: -3.5rem -2rem 0 auto;
            }
        }

        .modal-body {
            padding: 1.75rem 2rem;

            &.eshopItemTable {
                font-size: .875rem;

                .header {
                    font-weight: 700;
                }

                hr {
                    border-top-color: $color-green;
                }

                .body {
                    h5 {
                        font-size: 1rem;
                        margin: 0;
                    }
                }
            }
        }

        .modal-footer {
            border: none;
            padding: 0 2rem 1.75rem;
        }
    }
}
