.section.recipesDetail {
    &.header {
        position: relative;
        background: $color-dgreen center no-repeat;
        background-size: cover;
        height: 75vh;
        min-height: 35rem;

        .cover {
            content: "";
            position: absolute;
            inset: 0;
            background: transparent radial-gradient(closest-side at 77% 30%, #8A8A8A 0%, #003D15 100%) 0% 0% no-repeat padding-box;
            opacity: .4;
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 2.5rem 0 3.9rem;

            .comp__inputCheckboxToggle {
                .label {
                    font-size: .875rem;
                    color: white;
                    opacity: .75;
                    transition: opacity .3s $defaultTransition;
                }

                input[type="checkbox"]:checked {
                    + .input + .label {
                        opacity: 1;
                    }
                }
            }

            .headline {
                color: $color-white;

                .complexity {
                    display: flex;
                    flex-direction: column;
                    gap: .25rem;
                    font-size: .813rem;
                    font-weight: 700;
                    margin-bottom: 1.5rem;

                    .caps {
                        display: inline-flex;
                        align-items: center;
                        gap: .5rem;
                        line-height: 0;

                        svg {
                            width: 1.875rem;
                            height: auto;
                            fill: white;
                            opacity: .5;
                        }

                        @for $i from 0 through 5 {
                            &[data-complexity^="#{$i}"] {
                                svg:nth-child(-n + #{$i}) {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                .properties {
                    display: flex;
                    flex-wrap: wrap;
                    gap: .25rem;
                    margin-bottom: .75rem;

                    .property {
                        display: inline-flex;
                        background: $color-dgrey;
                        font-size: .813rem;
                        color: white;
                        border-radius: 3px;
                        font-weight: 700;
                        padding: .125rem 0.375rem;

                        &.green {
                            background: $color-green;
                        }
                    }
                }

                h1 {
                    font-weight: 900;
                    color: inherit;
                    letter-spacing: -.015em;
                    margin-bottom: .75rem;
                }

                p {
                    font-size: .875rem;
                    font-weight: 500;
                    line-height: 1.5;
                    margin: 0;
                }
            }

            @media (min-width: 768px) {
                .comp__inputCheckboxToggle {
                    .label {
                        font-size: 1rem;
                    }
                }

                .headline {
                    h1 {
                        max-width: 60%;
                    }

                    p {
                        font-size: .875rem;
                        font-weight: 700;
                    }
                }
            }

            @media (min-width: 992px) {
                .headline {
                    h1 {
                        max-width: 60%;
                    }

                    p {
                        font-size: 1.125rem;
                        line-height: 2;
                    }
                }
            }
        }
    }

    &.content {
        margin-top: 2.75rem;
        margin-bottom: 5rem;

        h2 {
            font-size: 2.25rem;
            font-weight: 900;
            font-family: $fontFamilySubtitles;
            color: $color-mbrown;
            margin-bottom: 1rem;
        }

        hr {
            border-top-color: $color-dblue;
            margin: 2.25rem 0;
        }

        .ingredients {
            display: flex;
            flex-direction: column;
            margin-bottom: 2.75rem;

            .item {
                font-weight: 500;
                letter-spacing: .01em;
                padding: .5rem .875rem;

                &:nth-child(even) {
                    background: rgba($color: $color-dblue, $alpha: .15);
                }
            }

            .head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: rgba($color: $color-dblue, $alpha: .4);
                padding-top: .625rem;
                padding-bottom: .625rem;

                h2 {
                    color: white;
                    font-size: 1.125rem;
                    font-family: $fontFamilyDefault;
                    letter-spacing: 0;
                    line-height: 1;
                    margin: 0;
                }

                .quantityWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: .25rem;
                    font-size: 0.75rem;
                    letter-spacing: .01em;
                    font-weight: 600;

                    .quantityCounter {
                        display: flex;

                        .count {
                            width: 2.25rem;
                            height: 100%;
                            color: $color-dblue;
                            font-weight: 700;
                            font-size: .938rem;
                            text-align: center;
                            background: white;
                            border: none;
                        }

                        .changer {
                            width: 2rem;
                            flex: 1;
                            color: white;
                            font-size: .875rem;
                            text-align: center;
                            text-decoration: none;
                            line-height: 1.9rem;
                            font-weight: 500;
                            cursor: pointer;
                            background: $color-dblue;
                            user-select: none;
                            transition: background-color .25s $defaultTransition;

                            &.dec {
                                border-radius: 6px 0 0 6px;
                            }

                            &.inc {
                                border-radius: 0 6px 6px 0;
                            }

                            &:hover {
                                background: $color-green;
                            }
                        }
                    }
                }
            }
        }

        .procedureOld {
            font-weight: 500;
            letter-spacing: .03em;

            ol {
                padding-left: 2.25rem;
                list-style-type: none;
                counter-reset: item 0;

                li {
                    margin-bottom: 1.375rem;
                    counter-increment: item;
                    position: relative;

                    &::before {
                        content: counter(item);
                        display: flex;
                        position: absolute;
                        justify-content: center;
                        top: 0;
                        left: -2.25rem;
                        width: 1.875rem;
                        font-size: 1.125rem;
                        font-weight: 700;
                        line-height: 1.25;
                        color: white;
                        text-align: center;
                        background: $color-mgreen;
                    }
                }
            }
        }

        .procedure {
            position: relative;
            font-weight: 500;
            letter-spacing: .03em;
            padding-left: 2.25rem;

            p {
                margin-bottom: 1.375rem;
            }

            img {
                max-width: 100%;
                margin-bottom: .75rem;

                &:last-of-type {
                    margin-bottom: 3.25rem;
                }
            }

            &:before {
                content: attr(data-step);
                position: absolute;
                top: 0;
                left: -1px;
                display: block;
                width: 1.875rem;
                font-size: 1.125rem;
                font-weight: 700;
                line-height: 1.25;
                color: white;
                text-align: center;
                background: $color-mgreen;
            }

            & + .video {
                margin-top: 4.5rem;
            }
        }

        .video {
            margin-bottom: .75rem;
        }

        .purchaseList {
            .addAll {
                margin-top: 1.5rem;

                .cart-image {
                    position: relative;

                    svg {
                        width: 1rem;
                        height: auto;
                    }

                    .cart-circle {
                        position: absolute;
                        top: -.5rem;
                        right: -.5rem;
                        background-color: $color-green;
                        color: white;
                        border-radius: 50%;
                        width: 1rem;
                        height: 1rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: .75rem;
                    }
                }
            }
        }

        @media (min-width: 768px) {
            .ingredients {
                .head {
                    .quantityWrapper {
                        flex-direction: row;
                        align-items: center;
                        font-size: .813rem;
                        gap: 1rem;
                    }
                }
            }
        }
    }
}
