.section.eshopCart.eshopCartContent {
    .cartItemBoxTab {
        .itemMain {
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
            }

            .imageBox {
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 9rem;
                    height: 9rem;
                    background-color: white;
                    border-radius: 1rem;
                    padding: 1rem;

                    img {
                        width: 75%;
                        height: 75%;
                        object-fit: contain;
                    }
                }
            }

            .infoBox {
                display: flex;
                flex-direction: column;
                max-width: 30rem;
                gap: .75rem;
                padding: .5rem 0;

                .properties {
                    display: flex;
                    gap: .25rem;

                    .property {
                        display: inline-flex;
                        background: $color-gray;
                        font-size: .75rem;
                        color: white;
                        border-radius: 15px;
                        font-weight: 500;
                        letter-spacing: -.01em;
                        padding: .125rem 1rem;
                    }
                }

                .category {
                    font-size: .75rem;
                    color: $color-gray;
                    font-family: $fontFamilyTitles;
                    font-weight: 500;
                    letter-spacing: -.01em;
                }

                .stock {
                    font-size: .875rem;
                    font-family: $fontFamilyTitles;
                    margin-top: auto;

                    .stockState {
                        font-weight: 700;

                        &.warning {
                            color: $color-orange;
                        }

                        &.error {
                            color: $color-red;
                        }
                    }

                    .manufacturer {

                        strong {
                            color: $color-green;
                        }
                    }
                }

                .name {
                    font-weight: 500;
                    color: $color-dgreen;
                }
            }

            .imageBox+.infoBox {
                margin-left: 1rem;
            }
        }

        .priceBox {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .counter {
                display: inline-flex;
                border-radius: 6px;
                overflow: hidden;

                .vetalg {
                    width: 2rem;
                    height: 2rem;
                    line-height: 2rem;
                    font-size: .875rem;
                    text-decoration: none;
                    text-align: center;
                    font-weight: 500;
                    color: white;
                    background: $color-dblue;
                    user-select: none;
                    transition: background-color .25s $defaultTransition;

                    &:hover {
                        background: $color-green;
                    }

                    &.disabled {
                        background-color: #8799A5;
                        pointer-events: none;
                    }
                }

                .fieldin {
                    height: 2rem;
                    text-align: center;
                    border: 1px solid rgba(37, 61, 78, .2);
                    border-left: none;
                    border-right: none;
                    outline: none;
                }

                &:not(.input-field) {
                    font-weight: 500;
                }
            }

            .price {
                font-weight: 700;
            }

            .removeItem,
            .voucherRemove {
                font-size: 1.75rem;
                font-weight: 700;
                line-height: 1;
                color: #D3D7D9;
                padding: .5rem;
                margin-left: 1rem;
                margin-right: -1rem;
            }
        }

        .itemSub {
            margin-top: 2px;

            .wrapper {
                border-radius: 5px;
                background: rgba(37, 61, 78, .025);
                padding-left: 1rem;

                .row {
                    max-width: 100%;
                }
            }
        }

        .priceBox+.itemSub {
            margin-top: 1rem;
        }

        &::after {
            content: "";
            display: block;
            height: 1px;
            background: rgba(37, 61, 78, .1);
            margin: 2rem 0;
        }

        &.--small {
            &:after {
                margin: .5em 0;
            }

            &:last-child:after {
                margin-bottom: 2em;
            }
        }

        &:not(.--small)+.--small {
            margin-top: -1.5em;
        }
    }

    .addons {
        margin-top: 1.25rem;

        .addon {
            display: flex;
            justify-content: space-between;

            .content {
                font-weight: 500;
            }

            .price {
                font-weight: 700;
            }
        }

        &::after {
            content: "";
            display: block;
            height: 1px;
            background: rgba(37, 61, 78, .1);
            margin: 1.25rem 0;
        }

        &+.addons {
            margin-top: 0;
        }
    }

    .cartItemBoxTab:not(.--small)+.addons {
        margin-top: -.75rem;
    }

    .bottomSection {
        .voucherLine {
            display: flex;
            padding-bottom: .5rem;

            input {
                max-width: calc(100% - 10rem);
                flex: 1;
                font-family: $fontFamilyDefault;
                font-weight: 500;
                font-size: .875rem;
                line-height: 3;
                background: white;
                border: 1px solid $color-lgray;
                border-radius: 4px 0 0 4px;
                outline: none;
                padding: 1px 1rem;

                &:focus,
                &:active {
                    outline: none;
                    border-color: $color-green;
                }
            }

            .btn::before {
                border-radius: 0 .5rem .5rem 0;
            }
        }

        .voucherBox {
            a {
                display: inline-flex;
                font-size: .875rem;
                text-decoration: none;
                gap: .5rem;
                margin-bottom: .5rem;

                svg {
                    width: .25rem;
                    height: auto;
                    stroke: $color-dblue;
                    margin-bottom: -2px;
                    transition: .25s $defaultTransition;
                }

                &:hover svg {
                    stroke: $color-orange;
                }
            }

            p {
                color: #8C8E97;
                font-size: .75rem;
                font-weight: 500;
            }
        }

        .ecoBox {
            .formItem {
                font-weight: 600;
                font-size: .875rem;
                margin-bottom: .5rem;

                label {
                    margin-left: .25rem;
                }
            }

            p {
                color: #8C8E97;
                font-size: .75rem;
                font-weight: 500;
            }
        }

        .deliveryFree {
            .state {
                display: flex;
                flex-wrap: wrap;
                gap: .25em;
                font-size: .875rem;
                font-weight: 600;
                margin: -.75rem 0 .5rem;

                svg {
                    width: 1.3rem;
                    height: auto;
                }
            }

            .progress {
                height: 3px;
                border-radius: 0;

                .progress-bar {
                    background-color: $color-dblue;
                }
            }

            &+.backToShop {
                margin-top: 1.25rem;
            }
        }
    }

    @media (max-width: 767.98px) {
        .ecoBox {
            margin: 1rem 0;
        }

        .deliveryFree {
            margin: 1rem 0;
        }

        .cartItemBoxTab .itemMain {
            margin-bottom: .25rem;

            .imageBox a {
                width: 5rem;
                height: 5rem;
            }

            .infoBox {
                gap: .25rem;
                font-size: .875rem;

                .stock {
                    font-size: .75rem;
                }
            }
        }
    }
}
