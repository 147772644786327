$groupsborder: #66a1e4;

$toggle-indicator-size: 16px;
$track-height: $toggle-indicator-size + 6;
$track-width: $toggle-indicator-size * 2.5;
$highContrastModeSupport: solid 2px transparent;
$speed: .2s;

#cookiesModal.modal.fade.less {
  form.gdpr_consent_form {
    height: 100%;

    .modal-dialog {
      width: 99% !important;
      height: 100%;
      max-width: 450px !important;
      border-radius: 0 !important;
      left: 0 !important;
      right: auto !important;
      margin: 0 !important;
      z-index: 10;

      .modal-content {
        border-radius: 0 !important;
        padding: 1rem;
        min-height: 100%;

        background-color: $color-dgreen !important;
        color: $color-white !important;
        border: none !important;

        .modal-header {
          border-bottom: none !important;


        }

        .modal-body {
          padding-bottom: 20px !important;

          .modal-title {
            color: white;
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.25;
            margin-bottom: 1rem;
          }

          h4.modal-title {
            font-size: 1rem;
          }

          &.flex {
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            flex-direction: column !important;
            padding: 0 !important;
          }

          .navButtons {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin-top: 1.5rem;

            .btnTemporary {
                min-width: 15rem;
              }
          }

          &.set {
            .nav-tabs {
              border-bottom: none !important;
            }

            ul {
              padding: 0 !important;
              list-style-type: none !important;

              li {
                width: 100% !important;
                margin-bottom: 10px !important;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;

                .item-content {
                  user-select: none;

                  a.listLink {
                    font-size: 16px !important;
                    color: white !important;
                    font-weight: bold;
                    line-height: .75;
                    padding: .5rem;
                    position: relative;
                    margin-left: auto;

                    span {
                      font-size: 16px !important;
                      color: white !important;
                      font-weight: bold;

                      &.openLabel {
                        position: relative;
                        opacity: 0;
                        transition: all .2s;
                      }

                      &.closeLabel {
                        position: absolute;
                        left: auto;
                        right: .5rem;
                        opacity: 1;
                        padding-right: .075rem;
                        transition: all .2s;
                      }
                    }

                    &:hover span {
                        color: $color-yellow !important;
                    }

                    &.collapsed {
                      span {
                        &.openLabel {
                          opacity: 1;
                          transition: all .2s;
                        }

                        &.closeLabel {
                          opacity: 0;
                          transition: all .2s;
                        }
                      }
                    }
                  }

                  .CookiesList {
                    background-color: transparent !important;
                    padding: 0 !important;
                    border-radius: 3px !important;
                    font-size: 13px !important;

                    h5 {
                      color: white !important;
                      font-size: 15px !important;
                      font-weight: bold !important;
                      text-align: left !important;
                    }

                    p {
                        font-size: .875rem !important;
                        opacity: 1;
                    }

                    ul {
                      li {
                        background: rgba(255, 255, 255, .1);
                        border: none !important;
                        border-radius: 5px;
                        padding: 1rem !important;
                        margin-bottom: .5rem !important;

                        .listRow {
                            display: flex;

                            h5 {
                                flex: 0 0 auto;
                            }

                            p {
                                flex: 1 1 auto;
                                text-align: right;
                                padding-left: 4rem;
                                margin: 0;
                                opacity: .75;
                            }

                            & + .listRow {
                                margin-top: .5rem;
                            }
                        }

                        &.emptyList {
                          p {
                            margin: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .toggle {
              align-items: center !important;
              border-radius: 100px !important;
              display: flex !important;
              font-weight: 700 !important;
              padding: 1rem 0;
              cursor: pointer !important;

              h4 {
                font-size: 1rem !important;
                font-weight: 600 !important;
                color: white !important;
                margin: 0 0 -.125rem;
                transition: all .3s;
              }

              * {
                box-shadow: none !important;
                outline: none !important;
              }

              &:last-of-type {
                margin: 0 !important;
              }
            }

            .toggle__input {
              clip: rect(0 0 0 0) !important;
              clip-path: inset(50%) !important;
              height: 1px !important;
              overflow: hidden !important;
              position: absolute !important;
              width: 1px !important;

              &:disabled + .toggle-track {
                cursor: not-allowed !important;
                opacity: 0.7 !important;
              }

              &:active + .toggle-track,
              &:focus + .toggle-track {
                box-shadow: none !important;
                border-color: $color-green !important;
              }

              &:not(:disabled) + .toggle-track:hover + h4,
              &:not(:disabled) + .toggle-track + h4:hover {
                color: $color-green !important;
              }
            }

            .toggle-track {
              background: none !important;
              border: 1px solid $color-green !important;
              border-radius: 100px !important;
              cursor: pointer !important;
              display: flex !important;
              height: $track-height !important;
              margin-right: 12px !important;
              position: relative !important;
              width: $track-width !important;
            }

            .toggle-indicator {
              background: $color-lgreen !important;
              border-radius: $toggle-indicator-size !important;
              bottom: 2px !important;
              display: flex !important;
              height: $toggle-indicator-size !important;
              justify-content: center !important;
              position: absolute !important;
              width: $toggle-indicator-size !important;
              transition: $speed !important;
            }

            .checkMark {
              fill: white !important;
              height: $toggle-indicator-size - 4 !important;
              width: $toggle-indicator-size - 4 !important;
              line-height: 0;
              opacity: 0;
              transition: opacity $speed ease-in-out !important;
            }

            .toggle__input:checked + .toggle-track .toggle-indicator {
              background: $color-green !important;
              transform: translateX($track-width - $track-height) !important;

              .checkMark {
                opacity: 1;
                transition: opacity $speed ease-in-out !important;
              }
            }
          }

          p {
            font-size: .875rem !important;
            color: white !important;
            line-height: 1.5 !important;
            font-weight: normal !important;
            opacity: .75;
          }
        }

        .modal-footer {
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          flex-direction: column !important;
          padding: 0 !important;
          border-top: none !important;
        }
      }
    }
  }

  &::after {
    width: 99% !important;
    max-width: 450px !important;
    border-radius: 0px !important;
    margin-top: 0;
    left: 0 !important;
    top: 0;
    right: auto;
    height: 100%;
    position: absolute;
    background: $color-dgreen;
    content: "";
    z-index: 1;
  }
}

@media screen and (-ms-high-contrast: active) {
  .toggle-track {
    border-radius: 0 !important;
  }
}
