.btnTemporary,
.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 10rem;
    min-height: 2.75rem;
    font-family: $fontFamilyTitles;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.25;
    text-decoration: none;
    color: white;
    gap: .5rem;
    background: none;
    border: none;
    border-radius: 0;
    padding: .25rem 1rem;
    z-index: 1;
    transition: .25s $defaultTransition;

    &.disabled {
        color: #8B8B8B !important;
        &::before {
            background: #ddd !important;
        }

        svg {
            fill: #8B8B8B !important;
            stroke: #8B8B8B !important;
        }

        .cart-image {
            display: none !important;
        }
    }

    &::before {
        position: absolute;
        inset: 0;
        content: "";
        border-radius: 5px;
        z-index: -1;
        transition: .25s $defaultTransition;
    }

    svg {
        fill: $color-white;
        stroke: $color-white;
        transition: .25s $defaultTransition;
    }

    .icoArrowUp {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid currentColor;
    }

    &:hover {
        &::before {
            transform: scale(1.1);
        }
    }

    &.btn--dgreen {
        &::before {
            background: $color-mgreen;
        }

        &:hover {
            color: $color-yellow;

            svg {
                fill: $color-yellow;
                stroke: $color-yellow;
            }
        }
    }

    &.btn--green {
        color: white;

        &::before {
            background: $color-green;
        }

        &:hover {
            color: $color-dgreen;

            svg {
                fill: $color-dgreen;
                stroke: $color-dgreen;
            }
        }
    }

    &.btn--lgreen {
        color: $color-green;

        svg {
            fill: $color-green;
            stroke: $color-green;
        }

        &::before {
            background: $color-lgreen;
        }

        &:hover {
            color: $color-dgreen;

            svg {
                fill: $color-dgreen;
                stroke: $color-dgreen;
            }
        }
    }

    &.btn--conversion {
        color: $color-conversion;
        color: $color-white;

        svg {
            fill: $color-conversion;
            stroke: $color-conversion;
        }

        &::before {
            background: $color-conversion;
        }

        &:hover {
            color: $color-white;

            svg {
                fill: $color-conversion;
                stroke: $color-conversion;
            }
        }
    }

    &.btn--purple {
        color: $color-lpurple;

        svg {
            fill: $color-lpurple;
            stroke: $color-lpurple;
        }

        &::before {
            background: $color-purple;
        }

        &:hover {
            color: $color-white;

            svg {
                fill: $color-white;
                stroke: $color-white;
            }
        }
    }

    &.btn--yellow {
        color: $color-white;

        &::before {
            background: $color-yellow;
        }

        &:hover {
            color: $color-white;

            svg {
                fill: $color-white;
                stroke: $color-white;
            }
        }
    }

    &.btn--orange {
        color: $color-white;

        &::before {
            background: $color-orange;
        }
    }

    &.btn--lgray {
        color: $color-dgreen;

        &::before {
            background: $color-lgray;
        }
    }

    &.btn--brown {
        color: $color-white;

        &::before {
            background: $color-brown;
        }
    }

    &.btn--text {
        min-height: 0;
        min-width: 0;
        color: $color-dblue;
        padding-left: 0;
        padding-right: 0;

        svg {
            fill: $color-dblue;
            stroke: $color-dblue;
        }

        &:hover {
            color: $color-green;

            svg {
                fill: $color-green;
                stroke: $color-green;
            }
        }
    }

    &.btn--small {
        min-width: 6.5rem;
        min-height: 2.25rem;
    }

    &.btn--medium {
        min-width: 11rem;
        min-height: 4rem;
    }

    &.btn--big {
        min-width: 19rem;
        min-height: 4rem;
    }

    &.btn--space-between {
        justify-content: space-between;
    }
}
