.section.eshopCart.eshopCartDeliveryInfo {
    .form {
        label {
            font-size: .75rem;
            font-weight: 500;
            color: #8C8E97;

            .asteriskField {
                margin-left: .15em;
            }
        }

        input,
        select {
            line-height: 2;

            &:disabled {
                background: #e3e3e3;
                color: #888;
            }

            &:focus {
                box-shadow: none;
                border-color: $color-green;
            }
        }

        textarea {
            height: calc(4 * 1.5em + .375rem * 2 + 2px);
            min-height: auto;
            border-color: #DCDCDC;
        }
    }
}
