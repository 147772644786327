.comp__breadcrumbs {
    display: flex;
    align-items: center;
    font-size: .75rem;
    font-family: $fontFamilyTitles;
    gap: .5rem;

    .breadcrumb {
        display: flex;
        align-items: center;
        color: $color-dblue;
        letter-spacing: .01em;
        text-decoration: none;
        font-weight: 700;
        margin: 0;

        .ico {
            display: none;
        }

        &.tag {
            background: #eceeef;
            font-size: 0;
            line-height: 2.5;
            border-radius: 12px;
            padding: 0 .5rem;

            .ico {
                display: block;
                background: none;
            }
        }

        &:hover {
            color: $color-green;
        }
    }

    .divider {
        font-weight: 700;
    }

    @media (max-width: 767.98px) {
        .breadcrumb:not(.tag) {
            white-space: nowrap;
            overflow: hidden;
            display: block;
            text-overflow: ellipsis;

            &:last-child {
                overflow: visible;
                text-overflow: initial;
            }
        }
    }

    @media (min-width: 768px) {
        gap: 1rem;

        .breadcrumb {
            gap: .5em;

            .ico {
                display: block;
                background: $color-lgray;
                border-radius: 12px;
                padding: .25rem;

                img {
                    width: 1rem;
                    height: 1rem;
                    object-fit: contain;
                }
            }

            &.tag {
                font-size: .875rem;
                padding: 0 1.75rem;
            }
        }
    }

    @media (min-width: 1400px) {
        .breadcrumb {
            font-size: .938rem;

            &.tag {
                font-size: .938rem;
                line-height: 2.25;
                padding: 0 1.5rem;
            }
        }
    }
}
