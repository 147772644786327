.comp__form {

    &.comp__form--crispy label,
    .form-label {
        color: #8c8e97;
        font-size: .75rem;
        font-weight: 500;
    }

    &.comp__form--crispy input:not([type="checkbox"]),
    input.form-control {
        appearance: none;
        background-clip: padding-box;
        background-color: var(--bs-body-bg);
        border: var(--bs-border-width) solid var(--bs-border-color);
        border-radius: var(--bs-border-radius);
        color: var(--bs-body-color);
        display: block;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        padding: .375rem .75rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        width: 100%;
        min-height: 46px;
        margin-bottom: 1rem;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active,
        &:-webkit-autofill::first-line {
            font-size: 1rem;
            font-weight: 400;
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        &:disabled {
            background: #e3e3e3;
            color: #888;
        }
    }

    select.form-select {
        min-height: 2.875rem;
    }

    input[type=password]+.form-text {
        text-align: right;

        a {
            font-size: .75rem;
            text-decoration: none;
        }
    }

    .errorlist,
    .alert-block ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        text-align: center;

        li {
            background: $color-red;
            border-radius: .375rem;
            color: white;
            font-weight: 600;
            padding: 1rem 1.5rem;

            &:before {
                display: none;
            }

            &+li {
                margin-top: .25em;
            }
        }
    }

    #delivery,
    #invoice,
    .invoiceWrapper {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        gap: .75rem;
        max-width: 600px;

        h1, h2 {
            font-size: 1.875rem;
            font-weight: 700;
        }

        &>* {
            grid-column: span 12;
            margin-bottom: 0 !important;
        }

        &>div[id^="div_id"] {
            grid-column: span 12;
        }

        &>div[id*="_city"] {
            grid-column: span 12;
        }

        &>div[id*="_zip"] {
            grid-column: span 12;
        }

        &>div[id*="note"],
        &>div[id*="invoice_company"] {
            grid-column: span 12;
        }

        &>div[id*="_state"] {
            display: none;
        }

        .mb-3 {
            margin-bottom: 0 !important;
        }

        .split-name-group,
        .split-street-group {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            gap: .75rem;
            grid-column: span 12;

            &>div {
                grid-column: span 12;
            }

            &>div[id*="split_street"] {
                grid-column: span 12;
            }

            &>div[id*="pre_nominals"],
            &>div[id*="post_nominals"] {
                grid-column: span 5;
                display: none;
            }

            &>div[id*="house_number"],
            &>div[id*="street_number"] {
                grid-column: span 12;
            }
        }

        @media (min-width: 500px) {

            &>div[id*="_city"] {
                grid-column: span 12;
            }

            &>div[id*="_zip"] {
                grid-column: span 12;
            }

            &>div[id*="_state"] {
                grid-column: span 12;
            }

            .split-name-group,
            .split-street-group {
                &>div {
                    grid-column: span 12;
                }

                &>div[id*="split_street"] {
                    grid-column: span 7;
                }

                &>div[id*="pre_nominals"],
                &>div[id*="post_nominals"] {
                    grid-column: span 3;
                }

                &>div[id*="house_number"],
                &>div[id*="street_number"] {
                    grid-column: span 5;
                }
            }
        }

        @media (min-width: 992px) {

            .split-name-group,
            .split-street-group {
                &>div {
                    grid-column: span 12;
                }

                &>div[id*="split_street"],
                &>div[id*="street_number"] {
                    grid-column: span 6;
                }

                &>div[id*="pre_nominals"],
                &>div[id*="post_nominals"] {
                    grid-column: span 4;
                }
            }
        }

        @media (min-width: 1400px) {

            .split-name-group,
            .split-street-group {
                &>div {
                    grid-column: span 12;
                }

                &>div[id*="split_street"] {
                    grid-column: span 8;
                }

                &>div[id*="pre_nominals"],
                &>div[id*="post_nominals"] {
                    grid-column: span 2;
                }

                &>div[id*="house_number"],
                &>div[id*="street_number"] {
                    grid-column: span 4;
                }
            }
        }
    }

    div.form-check[id^="div_id"] {
        &.form-check {
            position: relative;
            padding-left: 1.75rem;
            cursor: pointer;
            user-select: none;

            input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
            }

            label {
                font-size: .875rem;
                font-weight: 500;
                color: $color-dblue;
            }

            label:before {
                content: "";
                position: absolute;
                top: .025em;
                left: 0;
                width: 1.25rem;
                height: 1.25rem;
                border-radius: 6px;
                border: 1px solid #A3A8AB;
                transition: all .3s $defaultTransition;
            }

            label:after {
                content: "";
                display: block;
                position: absolute;
                top: .6rem;
                left: .6rem;
                width: .625rem;
                height: .625rem;
                background: url(/assets/img/svg/ico-check.svg) no-repeat center center;
                background-size: contain;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity .3s $defaultTransition;
            }

            & input[type="checkbox"]:checked+label {
                &:before {
                    background-color: $color-dblue;
                }

                &:after {
                    opacity: 1;
                }
            }
        }
    }
}
