.section.eshopCart.eshopCartDelivery {
    .cardBox {
        background: white;
        border-radius: 5px;
        list-style: none;
        padding: 2rem;
        margin-bottom: 2rem;

        .paymentgroup {
            transition: opacity .3s $defaultTransition;

            .comp__inputRadio {
                .delivery-module-detail {
                    position: relative;
                    bottom: -1px;
                    font-size: .725rem;
                    font-weight: 500;
                    opacity: .7;
                }

                .price {
                    font-weight: 700;
                    margin-left: auto;
                }
            }

            &.disabled {
                opacity: .5;

                .comp__inputRadio {
                    cursor: not-allowed;
                }
            }

            & + .paymentgroup {
                margin-top: .75rem;
            }
        }
    }

    @media (max-width: 767.98px) {
        .cardBox {
            padding: .5rem;

            span:not(.price) {
                font-size: .75rem;
            }

            img {
                display: none;
            }

            .delivery-module-detail {
                display: none;
            }
        }
    }
}
