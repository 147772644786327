.comp__downloads {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        text-decoration: none;
        background-color: white;
        border-radius: .5rem;
        border: 2px solid #e5e5e5;
        padding: 1rem 1.5rem;
        transition: .3s $defaultTransition;

        .name {
            font-size: .875rem;
            font-weight: 700;
            line-height: 1.5;

            .size {
                font-size: 87.5%;
                font-weight: 600;
                opacity: .5;
            }
        }

        svg {
            flex: 0 0 auto;
            stroke: currentColor;
        }

        &:hover {
            color: currentColor;
            background-color: $color-lgreen;
            border-color: #bcdba8;
            transform: scaleX(1.025);
        }

        @media (min-width: 1200px) {
            .name {
                font-size: 1rem;
            }
        }
    }
}
