.section.manufacturers {
    .wrapper {
        display: flex;
        flex-direction: column;

        .headline {
            h2 {
                margin-bottom: .75rem;
            }
        }

        .items {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: .5rem;
            margin-top: 1.25rem;

            .manufacturer {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 1rem;

                img {
                    height: 4rem;
                    max-width: 100%;
                    object-fit: contain;
                    object-position: center;
                    transition: transform .3s $defaultTransition;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }

        @media (min-width: 500px) {
            .items {
                grid-template-columns: repeat(3, 1fr);
            }
        }

        @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;

            .headline {
                white-space: nowrap;
            }

            .items {
                margin-top: 0;
                margin-left: 3rem;
            }
        }

        @media (min-width: 1200px) {
            .items {
                grid-template-columns: repeat(6, 1fr);
                gap: 1rem;
                margin-left: 4rem;

                .manufacturer {
                    img {
                        height: 7rem;
                    }
                }
            }
        }

        @media (min-width: 1680px) {
            .items {
                gap: 2rem;
                margin-left: 8rem;
            }
        }
    }
}
