@mixin font-face($font-family, $font-weight, $font-style, $font-file) {
  @font-face {
    font-family: $font-family;
    font-weight: $font-weight;
    font-style: $font-style;
    src: url(#{$fontPath}/#{$font-family}/#{$font-file}) format('opentype');
  }
}

// MuseoCyrillic
@include font-face("MuseoCyrillic", 100, normal, "MuseoCyrl_100.otf");
@include font-face("MuseoCyrillic", 100, italic, "MuseoCyrl_100_Italic.otf");
@include font-face("MuseoCyrillic", 300, normal, "MuseoCyrl_300.otf");
@include font-face("MuseoCyrillic", 300, italic, "MuseoCyrl_300_Italic.otf");
@include font-face("MuseoCyrillic", 500, normal, "MuseoCyrl_500.otf");
@include font-face("MuseoCyrillic", 500, italic, "MuseoCyrl_500_Italic.otf");
@include font-face("MuseoCyrillic", 700, normal, "MuseoCyrl_700.otf");
@include font-face("MuseoCyrillic", 700, italic, "MuseoCyrl_700_Italic.otf");
@include font-face("MuseoCyrillic", 900, normal, "MuseoCyrl_900.otf");
@include font-face("MuseoCyrillic", 900, italic, "MuseoCyrl_900_Italic.otf");

// MuseoSansCyrillic
@include font-face("MuseoSansCyrillic", 100, normal, "MuseoSansCyrl_100.otf");
@include font-face("MuseoSansCyrillic", 100, italic, "MuseoSansCyrl_100_Italic.otf");
@include font-face("MuseoSansCyrillic", 300, normal, "MuseoSansCyrl_300.otf");
@include font-face("MuseoSansCyrillic", 300, italic, "MuseoSansCyrl_300_Italic.otf");
@include font-face("MuseoSansCyrillic", 500, normal, "MuseoSansCyrl_500.otf");
@include font-face("MuseoSansCyrillic", 500, italic, "MuseoSansCyrl_500_Italic.otf");
@include font-face("MuseoSansCyrillic", 700, normal, "MuseoSansCyrl_700.otf");
@include font-face("MuseoSansCyrillic", 700, italic, "MuseoSansCyrl_700_Italic.otf");
@include font-face("MuseoSansCyrillic", 900, normal, "MuseoSansCyrl_900.otf");
@include font-face("MuseoSansCyrillic", 900, italic, "MuseoSansCyrl_900_Italic.otf");
