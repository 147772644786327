.section.eshopList {
    #categorymenu {
        .categoryList {
            display: flex;
            flex-wrap: wrap;
            gap: .5rem;
            margin-bottom: 1rem;

            .categoryTag {
                display: inline-flex;
                align-items: center;
                height: 2.5rem;
                font-size: .875rem;
                font-family: $fontFamilyTitles;
                font-weight: 700;
                color: $color-dblue;
                text-decoration: none;
                background-color: $color-lgray;
                border-radius: 12px;
                padding: 0 1rem;
                transition: .25s $defaultTransition;

                &:hover {
                    background-color: $color-dgreen;
                    color: white;
                }

                &.active {
                    background-color: $color-green;
                    color: white;
                }
            }

            & + hr {
                border-top-color: $color-green;
            }

            &:nth-of-type(2) {
                .categoryTag {
                    height: 2rem;
                    font-size: .825rem;
                    padding: 0 .875rem;
                }
            }

            &:nth-of-type(3) {
                .categoryTag {
                    height: 1.75rem;
                    font-size: .75rem;
                    padding: 0 .75rem;
                }
            }
        }
    }

    .filters {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem 1.5rem;
        margin-top: 1rem;

        .filter {
            font-family: $fontFamilyTitles;
            font-size: .875rem;
            font-weight: 700;

            svg {
                width: 1.25rem;
                height: 1rem;
                margin-right: -.25em;
            }

            .counter {
                background: #B5B5B5;
                color: white;
                font-size: .625rem;
                min-width: 1rem;
                text-align: center;
                line-height: 1;
                border-radius: 8px;
                padding: .25rem .25rem .125rem;
            }

            &:nth-child(4) svg path {
                stroke: $color-purple;
            }

            &:nth-child(3) svg path {
                fill: $color-orange;
            }
        }
    }

    .sort {
        display: flex;
        align-items: flex-start;
        font-family: $fontFamilyTitles;
        font-size: .875rem;
        margin-top: 1rem;

        button {
            background: none;
            border: none;
            color: #6C7880;
            padding: 0;
            margin-left: .25rem;

            &:hover {
                color: $color-mgreen;
                transition: color .3s $defaultTransition;
            }
        }
    }

    .comp__eshopItem {
        height: calc(100% - 1.5rem);
        margin-bottom: 1.5rem;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        gap: .5em;
        font-family: $fontFamilyTitles;

        .endless_page_current.active {
            font-size: .875rem;
            color: $color-dblue;
            align-self: flex-end;
            margin-bottom: .125rem;
        }

        .endless_separator,
        .endless_page_link {
            color: #6C7880;
            font-weight: 500;
            text-decoration: none;
            line-height: 1;
            transition: .25s $defaultTransition;
            padding: .25rem;

            svg {
                stroke: #6C7880;
                margin-top: -.25em;
            }

            &:hover {
                color: $color-green;

                svg {
                    stroke: $color-green;
                 }
            }

            &.active {
                color: $color-dgreen;
                font-weight: 700;
            }
        }
    }

    @media (min-width: 992px) {
        .categoryList {
            .categoryTag {
                height: 2.5rem;
                font-size: .875rem;
                padding: 0 1rem;
            }
        }

        .filters {
            margin-top: 0;
        }

        .sort {
            justify-content: flex-end;
            margin-top: 0;
        }
    }
}
