.comp__highlightBlockContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: white;
    border-radius: 14px;
    padding: 5.4rem 1rem;

    .ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 3.5rem;
        background: $color-yellow;
        border-radius: 100%;

        svg {
            stroke: $color-dblue;
        }

        span {
            font-family: $fontFamilyTitles;
            font-weight: 700;
            font-style: italic;
            font-size: 1.25rem;
        }
    }

    h1 {
        font-weight: 400;
        line-height: 1;
        margin-top: .3em;

        span {
            color: currentColor;
            font-size: 75%;
            letter-spacing: -.01em;

            strong {
                font-weight: 900;
            }
        }
    }

    p {
        max-width: 37em;
        font-size: 1.05rem;
        letter-spacing: .01em;
        text-align: center;
        margin: .125rem 0 2rem;
    }
}
