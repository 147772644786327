@import "bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$slick-font-path: "slick-carousel/slick/fonts/" !default;
$slick-loader-path: "slick-carousel/slick/" !default;
@import "slick-carousel/slick/slick";
@import "slick-carousel/slick/slick-theme";

@import "variables";
@import "colorize";
@import "fonts";
@import "buttons";
@import "navbar";
@import "footer";

@import "components/modal";
@import "components/cookies";
@import "components/form";
@import "components/inputRadio";
@import "components/inputCheckbox";
@import "components/inputCheckboxToggle";
@import "components/breadcrumbs";
@import "components/wysiwyg";
@import "components/highlightBlockContent";
@import "components/eshopItem";
@import "components/tagPill";
@import "components/messages";
@import "components/downloads";

@import "sections/banners";
@import "sections/products";
@import "sections/eshopList";
@import "sections/eshopCart";
@import "sections/eshopCartWizard";
@import "sections/eshopCartContent";
@import "sections/eshopCartDelivery";
@import "sections/eshopCartDeliveryInfo";
@import "sections/eshopCartSummary";
@import "sections/productCategories";
@import "sections/productDetail";
@import "sections/news";
@import "sections/manufacturers";
@import "sections/manufacturersList";
@import "sections/manufacturersDetail";
@import "sections/highlights";
@import "sections/blogPostDetail";
@import "sections/userProfile";
@import "sections/recipesList";
@import "sections/recipesDetail";

body {
    font-family: $fontFamilyDefault;
    background: #F9F9F9;
    color: $color-dblue;
    line-height: 1.25;
}

h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5 {
    font-family: $fontFamilyTitles;
    color: $color-dblue;
}

h1 {
    font-size: clamp(2rem, 3.3vw, 4rem);
    font-family: $fontFamilyTitles;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;

    span {
        color: $color-gray;
    }
}

h2 {
    font-size: 1.875rem;
    font-weight: 700;
    font-family: $fontFamilyTitles;
    font-style: normal;
    letter-spacing: -.01em;
}

h3 {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
}

a {
    color: $color-dgreen;
    font-weight: 500;
    text-decoration: underline;
    transition: color .25s $defaultTransition;

    &:hover {
        color: $color-orange;
    }

}

p {
    line-height: 1.5;
}

strong {
    font-weight: 700;
}

.clrGreen, .free {
    color: $color-green !important;
}

.container {
    max-width: 1200px;

    &.hd {
        max-width: 1600px;
    }
}

.container-fluid {
    width: 96vw;
    max-width: 1920px;

    @media (min-width: 567px) {
        width: 90vw;
    }

    @media (min-width: 1920px) {
        width: 80vw;
    }
 }

.cookies-banner {
    display: none;
}

.anchor {
    position: absolute;
    margin-top: -5rem;
    opacity: 0;
    height: 0;
    overflow: hidden;

    @media (min-width: 1200px) {
        margin-top: -9.25rem;
    }
}

.dropdown-menu {
    background: $color-mgreen;
    border-color: $color-lgray;
    padding: 0;
    overflow: hidden;

    .dropdown-item {
        color: white;
        font-size: .875rem;
        padding: .5rem .75rem;
        transition: .3s $defaultTransition;

        &:hover {
            color: $color-yellow;
            background-color: $color-dgreen;
        }

        &.active {
            color: $color-dgreen;
            background: white;
        }
    }
}

.LEAD_GENERATION {
    z-index: 3 !important;
}
