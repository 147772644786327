footer {
    .mainFooter {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .footerRow {
            flex: 1;

            &:nth-child(1) {
                flex: 1.5;
            }
        }
    }

    .logos {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        max-width: 24rem;
        padding-right: 3rem;

        .socials {
            display: flex;
            gap: 1rem;
        }

        a svg {
            will-change: transform;
            transition: transform .3s $defaultTransition;
        }

        a:hover svg {
            transform: scale(1.05);
        }
    }

    .subscribe {
        margin-top: 3rem;

        .title {
            font-family: $fontFamilySubtitles;
            font-size: 1.25rem;
            font-weight: 700;
            margin-bottom: .5rem;
        }

        .desc {
            font-size: .875rem;
            font-weight: 500;
            margin-bottom: 1rem;
        }

        .input-field {
            display: flex;

            input {
                max-width: calc(100% - 6rem);
                font-family: $fontFamilyDefault;
                font-weight: 500;
                font-size: .875rem;
                line-height: 3;
                background: white;
                border: 1px solid #CED3D6;
                border-radius: 6px 0 0 6px;
                padding: 1px 1rem;

                &:focus-visible {
                    outline: none;
                }
            }

            button {
                min-width: 6rem;

                &:before {
                    border-radius: 0 6px 6px 0;
                }
            }
        }
    }

    .colHeader {
        font-size: .875rem;
        font-weight: 700;
    }

    .footerRow {
        strong {
            font-size: .875rem;
            font-weight: 700;
        }

        p {
            line-height: 1;
        }

        a:hover {
            color: $color-green;
        }

        ul {
            list-style: none;
            padding: 0;
            margin-top: .75rem;

            li {
                font-size: .875rem;
                font-weight: 500;

                a {
                    color: $color-dgreen;
                    font-size: inherit;
                    font-weight: inherit;
                    text-decoration: none;
                }

                &+li {
                    margin-top: .75rem;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .mainFooter {
            flex-direction: row;
        }
    }
}
