.comp__wysiwyg {
    a:not(.btn) {
        color: $color-dgreen;
        font-weight: 600;
        text-decoration: underline;
        transition: color .25s $defaultTransition;

        &:hover {
            color: $color-orange;
        }
    }

    ul {
        position: relative;
        list-style: none;
        border-radius: 5px;
        margin: 0 0 1.5em;

        & > li {
            position: relative;
            letter-spacing: .2px;
            margin: -2px 0 0;
            padding-left: 15px;

            &:before {
                content: "";
                width: 5px;
                height: 5px;
                background-color: $color-dgreen;
                position: absolute;
                left: 0;
                top: .6em;
            }

            ul {
                margin: 0;
                padding-top: .75rem;
                padding-bottom: .75rem;
            }

            & + li {
                margin-top: .5em;
            }
        }
    }

    ol {
        margin-top: 1em;

        li + li {
            margin-top: .5em;
        }
    }

    p {
        line-height: 1.75;
        letter-spacing: .2px;
        margin: 0 0 1em;
    }

    strong {
        font-weight: 700;
        font-size: 1.1em;
    }

    hr {
        border-color: $color-orange;
    }

    h1 {
        font-size: clamp(1.5rem, 2.25vw, 3rem);
    }

    h2 {
        font-size: clamp(1.25rem, 1.4vw, 2.5rem);
    }

    h3 {
        font-size: clamp(1.1rem, 1.2vw, 2rem);
    }

    h4 {
        font-size: clamp(1rem, 1.1vw, 1.75rem);
    }

    h2, h3, h4 {
        margin-top: 1.5em;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .comp__form {
        ul {
            font-size: .875rem;
        }
    }
}
