.section.blogpost {
    &.header {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .headerBox {
            height: 45vh;
            min-height: 25rem;
            display: flex;
            align-items: flex-end;
            padding-bottom: 5.625rem;

            .container {
                position: relative;
                z-index: 3;
                .content {
                    .row.top {
                        display: flex;
                        justify-content: flex-start;

                        .sticker-time {
                            margin-bottom: 2rem;
                            span {
                                color: $color-blck;
                                text-align: center;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                padding-left: .7rem;
                                padding-right: .7rem;
                                padding-bottom: 0.1rem;
                                background: $color-yellow;
                                position: relative;
                                margin-right: 1.25rem;
                            }
                        }

                        .headline {
                            h1 {
                                color: $color-white;
                                max-width: 60%;
                            }
                        }
                    }
                }
            }
        }

        .cover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: linear-gradient(90deg, rgba(17, 22, 28, 0.60) 27.06%, rgba(17, 22, 28, 0.00) 50%);
            z-index: 2;
        }

        &.noImage {
            background-color: $color-dgreen;
        }
    }

    &.content {
        .imgCont {
            margin-bottom: 2rem;
        }
    }
}

.section.blogNav {
    ul.categoryList {
        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        margin-bottom: 1rem;
        list-style-type: none;
        padding-left: 0;

        li.nav-item  {
            a.nav-link {
                display: inline-flex;
                align-items: center;
                height: 2.9rem;
                font-size: .875rem;
                font-family: $fontFamilyTitles;
                font-weight: 700;
                color: $color-dblue;
                text-decoration: none;
                background-color: $color-lgray;
                border-radius: 12px;
                padding: 0 1.8rem;
                transition: .25s $defaultTransition;

                &:hover {
                    background-color: $color-dgreen;
                    color: white;
                }

                &.active {
                    background-color: $color-green;
                    color: white;
                }

                &.mntag {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 1rem;
                    height: auto;
                    min-height: 3rem;

                    h3 {
                        font-size: .875rem;
                        font-family: $fontFamilyTitles;
                        font-weight: 700;
                        color: $color-dblue;
                        text-decoration: none;
                        text-align: center;
                    }

                    span {
                        font-size: .675rem;
                        font-family: $fontFamilyTitles;
                        font-weight: 500;
                        color: $color-dgrey;
                        text-decoration: none;
                    }

                    &:hover, &:focus {
                        color: $color-white;

                        h3 {
                            color: $color-white;
                        }

                        span {
                            color: $color-white;
                        }
                    }
                }
            }

            & + hr {
                border-top-color: $color-green;
            }

            &.active {
                a {
                    background-color: $color-green;
                    color: white;
                }
            }
        }

        &.undermenu {
            li.nav-item  {
                a.nav-link {
                    display: inline-flex;
                    align-items: center;
                    height: 2.2rem;
                    font-size: .875rem;
                    font-family: $fontFamilyTitles;
                    font-weight: 700;
                    color: $color-dblue;
                    text-decoration: none;
                    background-color: $color-lgray;
                    border-radius: 12px;
                    padding: 0 1rem;
                    transition: .25s $defaultTransition;

                    &:hover {
                        background-color: $color-dgreen;
                        color: white;
                    }

                    &.active {
                        background-color: $color-green;
                        color: white;
                    }
                }

                & + hr {
                    border-top-color: $color-green;
                }

                &.active {
                    a {
                        background-color: $color-green;
                        color: white;
                    }
                }
            }
        }
    }
}
