.comp__inputCheckboxToggle {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 700;
    gap: .875rem;
    cursor: pointer;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .input {
        position: relative;
        width: 4rem;
        height: 2.125rem;
        background-color: rgba(255,255,255,0.5);
        border-radius: 1rem;
        transition: all .4s $defaultTransition;

        .switch {
            position: absolute;
            top: 50%;
            left: .125rem;
            width: 1.875rem;
            height: 1.875rem;
            background-color: $color-dgreen;
            border-radius: 50%;
            transform: translateY(-50%);
            transition: all .3s $defaultTransition;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: .938rem;
                height: .938rem;
                background: url(/assets/img/svg/ico-close.svg) no-repeat center center;
                background-size: contain;
                transform: translate(-50%, -50%);
                opacity: 1;
                transition: opacity .3s $defaultTransition;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                width: 1rem;
                height: 1rem;
                background: url(/assets/img/svg/ico-check.svg) no-repeat center center;
                background-size: contain;
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: opacity .3s $defaultTransition;
            }
        }
    }

    &:hover .input {
        background-color: $color-lgray;
    }

    input[type="checkbox"]:checked + .input {
        background-color: white;

        .switch {
            background-color: $color-green;
            transform: translate(1.875rem, -50%);

            &:before {
                opacity: 0;
            }

            &:after {
                opacity: 1;
            }
        }
    }

    &:hover input[type="checkbox"]:checked + .input {
        background-color: $color-lgray;
    }
}
