.section.recipesList {
    .pagination {
        display: flex;
        justify-content: flex-end;
        gap: .5em;
        font-family: $fontFamilyTitles;

        .endless_page_current.active {
            font-size: .875rem;
            color: $color-dblue;
            align-self: flex-end;
            margin-bottom: .125rem;
        }

        .endless_separator,
        .endless_page_link {
            color: #6C7880;
            font-weight: 500;
            text-decoration: none;
            line-height: 1;
            transition: .25s $defaultTransition;
            padding: .25rem;

            svg {
                stroke: #6C7880;
                margin-top: -.25em;
            }

            &:hover {
                color: $color-green;

                svg {
                    stroke: $color-green;
                 }
            }

            &.active {
                color: $color-dgreen;
                font-weight: 700;
            }
        }
    }

    .filters {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: .75rem 1.25rem;
        margin: 1.5rem 3px 1.25rem;

        .filter {
            font-size: .938rem;
            letter-spacing: .01em;
        }
    }

    .recipes {
        display: grid;
        gap: 1.5rem;

        .recipe {
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            color: $color-grayBlue;
            text-decoration: none;
            margin-bottom: 1rem;

            .cover {
                position: relative;
                height: 20vh;
                min-height: 11rem;
                border-radius: 12px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }

                .arrow {
                    position: absolute;
                    bottom: .75rem;
                    right: .75rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 3.5rem;
                    height: 3.5rem;
                    z-index: 1;

                    &:before {
                        content: '';
                        position: absolute;
                        inset: 0;
                        background: $color-lgray;
                        border: 4px solid white;
                        border-radius: 50%;
                        transition: .25s $defaultTransition;
                        will-change: transform;
                        z-index: -1;
                    }

                    svg {
                        stroke: $color-dgreen;
                    }
                }
            }

            .content {
                margin-top: .75rem;

                .complexity {
                    display: flex;
                    align-items: center;
                    gap: .75rem;
                    font-size: .813rem;
                    color: $color-dblue;
                    font-weight: 700;

                    .caps {
                        display: inline-flex;
                        align-items: center;
                        gap: .25rem;
                        line-height: 0;

                        svg {
                            width: 1.125rem;
                            height: auto;
                            fill: $color-dblue;
                            opacity: .35;
                        }

                        @for $i from 0 through 5 {
                            &[data-complexity^="#{$i}"] {
                                svg:nth-child(-n + #{$i}) {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                .title {
                    font-size: 1.125rem;
                    font-family: $fontFamilyDefault;
                    font-weight: 700;
                    letter-spacing: -.01em;
                    line-height: 1.25;
                    color: $color-dblue;
                    text-transform: none;
                    margin: .875rem 0 .2rem;
                }

                .properties {
                    display: flex;
                    flex-wrap: wrap;
                    gap: .25rem;

                    .property {
                        display: inline-flex;
                        background: $color-dgrey;
                        font-size: .813rem;
                        color: white;
                        border-radius: 3px;
                        font-weight: 700;
                        padding: .125rem 0.375rem;

                        &.green {
                            background: $color-green;
                        }
                    }
                }

                .perex {
                    font-size: .938rem;
                    margin-top: .75rem;
                    padding-right: .75rem;
                }
            }

            &:hover .cover .arrow::before {
                background: $color-lgreen;
                border-color: $color-lgreen;
                border-width: 8px;
                transform: scale(1.1);
            }
        }
    }

    @media (min-width: 500px) {
        .recipes {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    @media (min-width: 992px) {
        .recipes {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media (min-width: 1920px) {
        .recipes {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}
