.section.eshopCart.eshopCartWizard {
    padding-bottom: 0;

    .nav {
        width: 100%;

        li {
            flex: 0 0 auto;
            width: calc(100 / 3 * 1% - 2rem / 3);

            a {
                display: flex;
                align-items: center;
                gap: .75rem;
                text-decoration: none;

                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 auto;
                    width: 2rem;
                    height: 2rem;
                    color: white;
                    font-size: .875rem;
                    font-weight: 700;
                    background: $color-dblue;
                    border: 1px solid $color-dblue;
                    border-radius: 100%;
                    transition: all .25s $defaultTransition;

                    svg {
                        width: 1rem;
                        height: auto;
                        stroke: white;
                    }
                }

                p {
                    margin: 0;
                }

                .line {
                    flex: 1 0 auto;
                    height: 2px;
                    background: $color-dblue;
                    margin-left: .5rem;
                    margin-right: 1.25rem;
                }

                &.active {
                    color: $color-green;
                    font-weight: 700;
                    .line {
                        opacity: .25;
                    }

                    span {
                        background: $color-green;
                        border-color: $color-green;
                    }
                }

                &.deactivated {
                    opacity: .25;
                    pointer-events: none;

                    span {
                        color: $color-dblue;
                        background: none;

                        svg {
                            stroke: $color-dblue;
                        }
                    }
                }

                &:hover {
                    color: $color-green;
                    span {
                        border-color: $color-green;
                        background-color: $color-green;
                    }
                }
            }

            &:nth-child(4) {
                width: auto;
            }
        }
    }

    @media (max-width: 767.98px) {
        .nav {
            li {
                a {
                    p {
                        display: none;
                    }
                }
            }
        }
    }

    @media (max-width: 1199.98px) {
        .nav {
            li {
                a {
                    &.passed,
                    &.deactivated {
                        p {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
