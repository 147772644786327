.comp__inputRadio {
    display: flex;
    align-items: center;
    font-family: $fontFamilyTitles;
    font-size: .875rem;
    font-weight: 700;
    gap: .4em;
    cursor: pointer;
    user-select: none;


    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .input {
        position: relative;
        top: -.05em;
        flex: 0 0 auto;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
        border: 1px solid #A3A8AB;
        transition: all .4s $defaultTransition;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: .625rem;
            height: .625rem;
            background: url(/assets/img/svg/ico-check.svg) no-repeat center center;
            background-size: contain;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity .3s $defaultTransition;
        }
    }

    &:hover {
        .input {
            background-color: $color-lgray;
        }
    }

    input[type="radio"]:checked + .input {
        background-color: $color-dblue;

        &:after {
            opacity: 1;
        }
    }
}
