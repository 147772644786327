.navbar {
    background-color: $color-dgreen;

    .topnav {
        max-width: 1920px;

        .navbar-brand {
            min-height: 5rem;
            max-width: 32vw;
            display: flex;
            align-items: center;
            align-self: stretch;
            transition: min-height .3s $defaultTransition;

            svg {
                height: auto;
                margin-top: -.3em;
                transition: all .3s $defaultTransition;
            }

            &:hover,
            &:focus {
                svg {
                    transform: scale(1.1);
                }
            }
        }

        .offcanvas {
            background-color: $color-dgreen;
            color: $color-white;
            transform: none;
            opacity: 0;
            transition: opacity .4s $defaultTransition;

            &.show, &.showing {
                opacity: 1;
            }

            &.hiding {
                opacity: 0;
            }

            .offcanvas-header {
                min-height: 5rem;
                margin: 0 2vw;
                padding: 5px calc(var(--bs-gutter-x) * .5);
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);

                svg {
                    max-width: 32vw;
                    margin-top: -.3em;
                }

                button.btn-close {
                    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27white%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
                    opacity: 1;
                }
            }

            .offcanvas-body {
                margin: 0 2vw;
                padding: 1.5rem calc(var(--bs-gutter-x) * .5);

                .searchInput {
                    input {
                        width: 100%;
                    }
                }

                .navbar-nav {
                    .nav-item {
                        justify-content: flex-start;

                        a {
                            gap: .5rem;
                            margin-left: -1rem;
                            padding-top: .25rem;
                            padding-bottom: .25rem;

                            svg {
                                fill: white;
                                height: auto;
                                width: 0.875rem;
                                margin-top: -1px;
                            }
                        }
                    }

                    &.main {
                        .nav-item {
                            font-size: 1.125rem;
                        }
                    }

                    &.main.categories {
                        position: relative;
                        margin: 1.5rem 0;
                        padding: 1.5rem 0;

                        .nav-item {
                            font-size: .875rem;

                            a {
                                font-weight: 500;
                            }
                        }

                        &:before,
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: -12px;
                            right: -12px;
                            height: 1px;
                            background: rgba(255, 255, 255, 0.2);
                        }

                        &:after {
                            top: auto;
                            bottom: 0;
                        }
                    }
                }
            }

            @media (min-width: 567px) {
                .offcanvas-header,
                .offcanvas-body {
                    margin: 0 5vw;
                }
            }
        }

        .offcanvas-backdrop {
            transition: opacity .4s $defaultTransition;
        }

        ul.navbar-nav {

            &.main {
                gap: .5rem;

                li {
                    display: flex;
                    justify-content: center;
                    align-items: stretch;


                    a {
                        color: $color-white;
                        font-family: $fontFamilyTitles;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0 1rem;

                        &:hover {
                            color: $color-yellow;
                        }
                    }
                }
            }

            &.special {
                display: flex;
                flex-direction: row;
                gap: .5rem;

                &.white {
                    li {
                        a:not(.btn) {
                            color: $color-white;
                            font-weight: 500;

                            &:hover {
                                color: $color-yellow;
                            }
                        }
                    }
                }

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &.cart {
                        a {
                            position: relative;
                            text-decoration: none;

                            .cart-image {
                                position: relative;

                                svg {
                                    stroke: $color-dgreen;
                                }

                                .cart-circle {
                                    position: absolute;
                                    top: -.4rem;
                                    right: -.4rem;
                                    background-color: $color-dgreen;
                                    color: white;
                                    border-radius: 50%;
                                    width: 1rem;
                                    height: 1rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    font-size: .7rem;
                                }
                            }

                            .price {
                                display: block;
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                text-decoration: none;
                            }


                        }

                    }
                }
            }

            li {

                &.active {
                    a {
                        color: $color-yellow;
                    }
                }
            }

        }
    }

    .navbar-toggler {
        margin-right: calc(-1 * var(--bs-navbar-toggler-padding-x) - 0.125em);
        border: none;
        box-shadow: none;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
        }
    }

    .searchInput {
        display: flex;

        input {
            width: clamp(10rem, 20vw, 30rem);
            font-family: $fontFamilyDefault;
            font-weight: 500;
            font-size: .875rem;
            line-height: 3;
            background: white;
            border: none;
            border-radius: 4px 0 0 4px;
            padding: 1px 1rem;

            &:focus-visible {
                outline: none;
            }
        }

        button {
            color: white;
            font-family: $fontFamilyTitles;
            font-size: .875rem;
            font-weight: 700;
            background: $color-mgreen;
            border: none;
            border-radius: 0 4px 4px 0;
            border-left: 1px solid $color-mgreen;
            padding: 0 1rem;
            transition: .3s $defaultTransition;

            &:hover {
                color: $color-mgreen;
                background: white;
            }
        }
    }

    @media (max-width: 1199.98px) {
        .topnav {
            ul.navbar-nav {
                &.special {
                    li:not(.alwaysVisible) {
                        display: none;
                    }

                    .btn {
                        font-size: 0;
                        gap: 0;
                        min-width: 0;

                        svg{
                            stroke: white;
                        }

                        &:before {
                            display: none;
                        }

                        &#cart {
                            .cart-image {
                                svg {
                                    stroke: white;
                                }

                                .cart-circle {
                                    background-color: $color-green;
                                }
                            }

                            .price {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .topnav {
            .offcanvas {
                display: none;
            }

            .navbar-brand {
                margin-right: 1.5rem;

                svg {
                    width: 9.75rem;
                    margin-top: -.4em;
                }
            }

            ul.navbar-nav {
                &.special {
                    .search {
                        padding: 0 .5rem;
                    }
                }

                &.special.white .nav-item .nav-link {
                    font-size: .875rem;
                    font-weight: 500;
                    padding: 0 .25rem;
                }
            }
        }

        &.navbar-scrolled {
            .topnav {
                .navbar-brand {
                    min-height: 4rem;

                    svg {
                        width: 8.5rem;
                    }
                }
            }
        }
    }

    @media (min-width: 1400px) {
        .topnav {
            ul.navbar-nav {
                &.special.white .nav-item .nav-link {
                    padding: 0 1rem;
                }
            }
        }
    }
}

.navbar#navbarCategories {
    top: 5rem;
    background: white;
    border-bottom: 1px solid #F2F3F5;
    transition: top .3s $defaultTransition;

    .undernav {
        flex: 0 0 auto;

        ul.navbar-nav {
            width: 100%;
            gap: .25rem;

            li {
                display: flex;
                justify-content: center;
                align-items: stretch;

                a {
                    height: 3.75rem;
                    color: $color-dgreen;
                    font-family: $fontFamilyTitles;
                    font-size: .8125rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: -.005em;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid transparent;
                    padding: 0 .5rem;
                    gap: .25rem;
                    margin: .5rem 0 -1px;
                    z-index: 1001;

                    svg {
                        margin-top: -.125rem;
                    }

                    &:hover {
                        color: $color-yellow;
                    }

                    &.active {
                        font-weight: 900;
                        letter-spacing: .01em;
                    }

                    &.dropdown-toggle {
                        white-space: normal;

                        &.show {
                            border-color: #E1DFDF;
                            border-bottom-color: white;
                            border-radius: 12px 12px 0 0;
                        }
                    }
                }

                .dropdown-menu {
                    left: 0;
                    right: 0;
                    max-height: calc(100vh - 80px - 67px);
                    background: white;
                    border: none;
                    border-top: 1px solid #E1DFDF;
                    border-bottom: 1px solid #E1DFDF;
                    border-radius: 0;
                    box-shadow: none;
                    padding: 2rem 0 3rem;
                    margin: 0;
                    overflow: auto;

                    .tagMenu {
                        display: flex;

                        .info {
                            flex: 1;

                            .title {
                                font-family: $fontFamilyTitles;
                                height: auto;
                                background: #eaeeef;
                                border-radius: 12px;
                                font-weight: 600;
                                font-size: .9625em;
                                color: $color-green;
                                text-align: center;
                                text-decoration: none;
                                padding: .85rem 1.75rem;
                                margin: 0.25rem 0;

                                &:hover {
                                    color: $color-dgreen
                                }
                            }
                        }

                        .tags {
                            flex: 5;
                            columns: 5 10rem;
                            column-gap: 1rem;
                            height: 38rem;
                            column-fill: auto;

                            .safariFixCont {
                                display: block;

                                ul {
                                    width: 100%;
                                    margin: 0 1rem 1rem 0;
                                    display: block;

                                    li {
                                        justify-content: flex-start;
                                        a {
                                            height: auto;
                                            text-align: left;
                                        }

                                        .nav-link {
                                            display: flex;
                                            align-items: flex-start;
                                            gap: .75em;
                                            font-weight: 500;
                                            height: auto;
                                            margin: 0;
                                            padding: 0.25rem .5rem;

                                            &.highlighted {
                                                color: $color-green;
                                                &:hover {
                                                    color: $color-orange;
                                                }
                                            }

                                            svg {
                                                fill: $color-orange;
                                            }

                                            &.withIco {
                                                flex-direction: column;
                                                display: block;
                                                break-inside: avoid;

                                                .ico {
                                                    width: 2.75rem;
                                                    height: 2.75rem;
                                                    background: #eaeeef;
                                                    border-radius: 12px;
                                                    padding: .5rem;
                                                    margin-bottom: .75em;

                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        object-fit: contain;
                                                    }
                                                }
                                            }
                                        }

                                        & + li {
                                            margin-top: .25rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.navbar-scrolled {
        top: 4rem;
    }

    @media (max-width: 1199.98px) {
        display: none;
    }

    @media (min-width: 1680px) {
        .undernav {
            ul.navbar-nav {
                li {
                    a.nav-link {
                        font-size: .875rem;
                        gap: .4rem;
                        padding: 0 .75rem;
                    }
                }
            }
        }
    }

    @media (min-width: 2560px) {
        .undernav {
            ul.navbar-nav {
                li {
                    a.nav-link {
                        padding: 0 1rem;
                    }
                }
            }
        }
    }
}

.navbar-offset {
    height: 5rem;
    pointer-events: none;

    @media (min-width: 1200px) {
        height: 9.25rem;
    }
}
