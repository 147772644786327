.container-fluid.section.user.account {
    .container.osatka {
        max-width: 1896px;
        padding-top: 4rem;
    }
    .menuheadline {
        h1 {
            font-size: 1.875rem;
            letter-spacing: -0.01em;
            line-height: 1.2;
            text-align: left;
            color: $color-dblue;
            margin-bottom: 3rem;
            font-weight: 900;

            span {
                font-weight: 500;
                color: $color-dblue;
            }
        }
    }

    #categorymenupr {
        padding-left: 0;
        list-style-type: none;

        li {
            padding-bottom: 1rem;
            a {
                font-weight: normal;
                font-size: 0.938rem;
                line-height: 1.2;
                text-align: left;
                color: $color-dblue;
                display: block;
                padding-left: 1rem;
                position: relative;
                padding-bottom: .3rem;
                text-decoration: none;

                &:hover {
                    color: $color-green;
                }
            }

            &.active {
                a {
                    font-weight: bold;
                    color: $color-dblue;

                    &::before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 3px;
                        background: $color-green;
                        content: "";
                    }
                }
            }
        }
    }
    .account-right{
        .pageheadline {
            h2 {
                font-weight: 700;
                font-size: 1.875rem;
                letter-spacing: -0.01em;
                line-height: 1.2;
                text-align: left;
                color: $color-dblue;
                margin-bottom: 3rem;
            }
        }

        .pageunderheadline {
            h3 {
                font-weight: 700;
                font-size: 1.675rem;
                letter-spacing: -0.01em;
                line-height: 1.2;
                text-align: left;
                color: $color-dblue;
                margin-bottom: 2rem;
            }

            &.detail {
                padding-bottom: 0;
                h3 {
                    font-size: 1.25rem;
                    margin-bottom: 1rem;
                }
            }
        }

        .profileInfo {
            margin-bottom: 4rem;
            border-radius: 12px;
            min-height: 120px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: $color-white;
            padding: 2.1rem;

            .name {
                display: flex;
                align-items: center;

                img {
                    width: 50%;
                    max-width: 72px;
                    height: auto;
                    margin-right: 2rem;
                    border-radius: 50%;
                }

                p {
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 1.3;
                    color: $color-dblue;
                    margin-bottom: 0;

                    a {
                        color: $color-green;
                    }
                }
            }

            .pricegroup {
                font-weight: normal;
                font-size: 1.8rem;
                line-height: 1.9rem;
                color: #191760;

                span {
                    font-weight: bold;
                }
            }
        }

        .ordersHistoryCont {

            .row.corr {
                margin-left: 0;
                margin-right: 0;
            }

            .orderBox {
                border: 1px solid $color-white;
                border-radius: 12px;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 1rem;
                text-decoration: none;
                transition: all .3s;
                background: $color-white;

                &.inactive {
                    background-color: $color-white;

                    .bdr {
                        transition: all .3s;
                        min-height: 120px;
                        display: flex;
                        justify-content: flex-start;
                        align-content: center;

                        .firstCont::after{
                            display: none;
                        }

                        .odate, .oheadline {
                            display: none;
                        }

                        .oheadline span {
                            opacity: .4;
                        }

                        .oprice span {
                            color: rgba($color: #2e3045, $alpha: .6);
                            padding-bottom: 0;
                        }
                    }
                    &:hover {
                        background-color: $color-white;
                        border-color: $color-white;


                        .bdr {
                            border-color: $color-white;
                            transition: all .3s;
                        }
                    }
                }

                &.detail {
                    &:hover {
                        background: $color-white;
                        border-color: $color-white;
                        transition: all .3s;

                        .bdr {
                            border-color: $color-white;
                            transition: all .3s;
                        }
                    }

                    .oprice {
                        margin-top: 1rem;

                        span {
                            font-size: 1.125rem !important;
                        }
                    }

                    .ostate.invoice {
                        span {
                            a {
                                color: $color-green;
                            }
                        }
                    }
                }

                .bdr {
                    border: 2px solid #fff;
                    border-radius: 12px;
                    padding: 1.5rem;
                    transition: all .3s;

                    .firstCont {
                        position: relative;

                        &::after {
                            position: absolute;
                            right: 1.5rem;
                            width: 1px;
                            top: 10%;
                            height: 80%;
                            background: rgba($color: #191760, $alpha: .2);
                            content: "";
                        }
                    }

                    .odate {
                        padding-bottom: 1rem;
                        span {
                            font-size: 0.813rem;
                            letter-spacing: -0.01em;
                            line-height: 1.2;
                            text-align: left;
                            color: $color-dblue;
                        }
                    }

                    .oprice {
                        padding-bottom: .5rem;
                        span {
                            font-weight: bold;
                            font-size: 1.125rem;
                            letter-spacing: -0.01em;
                            line-height: 1.2;
                            text-align: left;
                            color: $color-green;
                        }
                    }

                    .ostate {
                        span {
                            font-weight: normal;
                            font-size: 0.813rem;
                            line-height: 1.2rem;
                            text-align: left;
                            color: $color-dblue;

                            &.new {
                                font-size: 0.75rem;
                                color: $color-white;
                                background: $color-dblue;
                                border-radius: 20px;
                                padding: .1rem .4rem;
                            }
                        }
                    }

                    .oheadline {
                        padding-bottom: 1rem;
                        padding-left: 0;
                        span {
                            font-weight: 700;
                            font-size: 0.813rem;
                            line-height: 1.2rem;
                            text-align: left;
                            color: $color-dblue;
                        }
                    }

                    .content {
                        ul {
                            padding-left: 0;
                            list-style-type: none;

                            li {
                                font-weight: normal;
                                font-size: 0.813rem;
                                line-height: 1.3;
                                text-align: left;
                                color: $color-dblue;
                            }
                        }
                    }

                    @media (max-width: 1199.98px) {
                        .firstCont {
                            padding-bottom: 2rem;

                            &::after {
                                top: auto;
                                left: .75rem;
                                right: .75rem;
                                bottom: 1rem;
                                width: auto;
                                height: 1px;
                            }
                        }

                        .oheadline {
                            padding-bottom: .25rem;
                        }
                    }
                }

                &:hover, &:focus {
                    background: rgba($color: $color-green, $alpha: .08);
                    border: 1px solid #D9E3E2;
                    transition: all .3s;

                    .bdr {
                        border: 2px solid #D9E3E2;
                        transition: all .3s;
                    }
                }
            }
        }

        .masterCartBox {
            border-radius: 12px;
            border: 1px solid rgba($color: #191760, $alpha: .2);

            &.detail {
                border: none;
            }
        }

        .cartBoxLegend {
            min-height: 41px;
            border-radius: 12px 12px 0px 0px;
            background: $color-dblue;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 1.5rem;

            .legendItem {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span {
                font-weight: 500;
                font-size: 0.813rem;
                line-height: 1.3;
                text-align: left;
                color: $color-white;
                text-transform: none;
            }
        }

        .cartItemBoxTab {
            background: $color-white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            margin-top: .4rem;
            border: none;
            border-radius: 6px;

            .row {
                margin: 0;
                width: 100%;
            }

            .imagebox {
                padding-left: 1.5rem !important;
                img {
                    width: 100%;
                    max-width: 80px;
                    border-radius: 12px;
                }
            }

            .namebox {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                a {
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 1.3rem;
                    text-align: left;
                    color: $color-dblue;
                    text-decoration: none;
                }

                span {
                    padding-left: 2.5rem;
                }

            }

            .countbox {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 0.813rem;
                line-height: 1.3;
                text-align: center;
                color: $color-dblue;

                .cart-counter {
                    min-height: 61px;
                    width: 120px;
                    border-radius: 30.5px;
                    background: transparent;
                    border: 1px solid rgba($color: #191760, $alpha: .2);
                    position: relative;

                    .minusitem {
                        position: absolute;
                        left: 5px;
                        width: 30px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 2;

                        a {
                            font-weight: normal;
                            font-size: 2.3rem;
                            line-height: 2rem;
                            text-align: right;
                            color: #191760;
                        }
                    }

                    .field {
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        input {
                            font-weight: 500;
                            font-size: 1.3rem;
                            line-height: 1.3rem;
                            color: #2e3045;
                            display: block;
                            text-align: center;
                            border: none;
                        }

                    }

                    .plusitem {
                        position: absolute;
                        right: 5px;
                        width: 30px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 2;

                        a {
                            font-weight: normal;
                            font-size: 2.3rem;
                            line-height: 2rem;
                            text-align: left;
                            color: #191760;
                        }
                    }
                }
            }

            .pricebox {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                font-size: 0.813rem;
                line-height: 1.3;
                text-align: center;
                color: $color-dblue;
            }
        }
    }

    .free-delivery {
        padding: 0 !important;

        .freeDelIn {
            background: $color-white;
            border-radius: 12px;
            margin-top: .4rem;
            min-height: 31px;
            display: flex;
            justify-content: flex-start;
            align-content: center;
            align-items: center;

            .in {
                display: flex;
                justify-content: flex-start;
                align-content: center;
                align-items: center;
                font-size: 0.813rem;
                color: $color-dblue;
                font-weight: 700;
                padding-left: 1.5rem;
            }
        }
    }
}

.container-fluid.master {
    .container-fluid.section.user.account {
        h4 {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 1.2;
            text-align: left;
            color: $color-dblue;
            margin-bottom: 2rem;
        }

        #deliveryInfoForm, #passForm {
            left: 0;
            transform: none;

            .btn.btn-primary {
                background-color: #dff3b8;
                border-color: #dff3b8;
                color: #8abb2a;
                font-size: .825rem;
                font-weight: 900;
                transition: all .3s;
                max-width: 260px;
                border-radius: 6px;
                margin-top: 3rem;
                width: 100%;
                min-height: 64px;

                &:hover {
                    background-color: #dff3b8;
                    color: #13463f;
                    transition: all .3s;
                }
            }

            #id_password1_helptext {
                ul {
                    li {
                        font-size: 0.75rem;
                    }
                }
            }
        }
    }
}



@media (max-width:1199.98px) {
    .container-fluid.section.seven.bloglist.account .account-right .ordersHistoryCont .orderBox .bdr {
        .firstCont {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 4rem;

            &::after {
                right: 10%;
                width: 80%;
                top: auto;
                bottom: 2rem;
                height: 1px;
            }
        }

        .content {
            padding-bottom: 1rem;
        }
    }
}

@media (max-width: 767.89px) {

    .container-fluid.section.seven.bloglist.account {
        .account-right {
            .cartItemBoxTab {
                .imagebox, .namebox {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 2rem;
                }
            }
        }

        .cartItemBoxTab .pricebox, .cartItemBoxTab .countbox {
            span {
                font-size: 1.4rem;
                font-weight: 400;
                display: block;
                width: 100%;
                text-align: center;
                margin-bottom: .5rem;
            }

            &.main {
                font-size: 1.8rem;
                margin-bottom: .8rem;
            }
        }

        .cartItemBoxTab .removebox {
            span {
                display: block;
                font-size: 1.3rem;
                font-weight: 400;
                color: #191760;
            }
        }
    }

    .container-fluid.section.user.account .account-right .cartItemBoxTab .namebox a, .container-fluid.section.user.account .account-right .cartItemBoxTab .namebox span {
        padding-left: 2.5rem;
        padding-right: 2.5rem;

    }

    .container-fluid.section.user.account .account-right .cartItemBoxTab .namebox a {
        margin-bottom: 5px;
        font-size: 0.813rem;
        line-height: 1.3;
    }

    .container-fluid.section.user.account .account-right .cartItemBoxTab .namebox span {
        font-size: 0.813rem;
        line-height: 1.3;
    }

    .container-fluid.section.user.account .account-right .cartItemBoxTab .pricebox, .container-fluid.section.user.account .account-right .cartItemBoxTab .countbox {
        justify-content: flex-start;
        padding-left: 2.5rem !important;
        padding-top: 2px;

        span {
            font-weight: 400;
            padding-right: .8rem;
        }
    }
}
