.section.eshopCart {
    padding-top: 3rem;
    padding-bottom: 3rem;

    .masterCartBox {
        max-width: 80rem;
        margin: 0 auto;
    }

    .headline {
        h1, h2 {
            font-size: 1.875rem;
            font-weight: 700;
        }

        h3 {
            font-size: 1.25rem;
            margin-bottom: 0.5em;
        }

        h4 {
            font-size: .875rem;
            font-weight: 700;
            border-bottom: 2px solid rgba($color: $color-dblue, $alpha: .25);
            padding-bottom: .25em;
        }
    }

    .contentCartSmallBox {
        .smallItem {
            display: flex;
            align-items: center;
            width: 100%;
            background: white;
            border-radius: 5px;
            padding: .5rem;

            img {
                width: 3rem;
                height: 4rem;
                flex: 0 0 auto;
                object-fit: contain;
            }

            .content {
                padding: 0 2rem 0 .5rem;

                .properties {
                    display: inline-flex;
                    gap: .25rem;

                    .property {
                        display: inline-flex;
                        background: $color-gray;
                        font-size: .75rem;
                        color: white;
                        border-radius: 15px;
                        font-weight: 500;
                        letter-spacing: -.01em;
                        padding: .125rem .4rem;
                    }
                }

                .category {
                    font-size: .75rem;
                    color: $color-gray;
                    font-family: $fontFamilyTitles;
                    font-weight: 500;
                    letter-spacing: -.01em;
                    margin: 0 0 .25rem;
                }

                .title {
                    display: flex;
                    font-size: .775rem;
                    font-weight: 400;
                    color: $color-dblue;
                    letter-spacing: -.01em;
                    text-decoration: none;
                }

                .stock {
                    font-size: .775rem;
                    font-weight: 400;
                }
            }

            .master {
                font-size: .875rem;
                font-weight: 700;
                color: $color-dblue;
                margin-left: auto;
                padding-right: .75rem;

                .price {
                    font-weight: 400;
                    &.main {
                        font-weight: 700;
                        font-size: 1.1rem;
                    }
                }
            }

            &+.smallItem {
                margin-top: .25rem;
            }

            &.--sub {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                margin-top: -.75rem;
                .smallItem {
                    background: rgba(37, 61, 78, .025);
                    padding: .25rem;
                }
            }
        }

        .divider {
            height: 2px;
            background: $color-dblue;
            opacity: .25;
            margin: .25rem 0;
        }
    }

    .bottomSection {
        .btnsBox {
            margin-top: 2rem;
        }

        .backToShop {
            display: inline-flex;
            text-decoration: none;
            gap: .5rem;

            svg {
                width: .4rem;
                height: auto;
                stroke: $color-dblue;
                margin-bottom: -2px;
                transition: .25s $defaultTransition;
            }

            &:hover svg {
                stroke: $color-orange;
            }

            &.underDelivery {
                @media (max-width: 767.98px) {
                    display: none;
                }
            }

            &.nextContinuation {
                margin: 0;

                @media (min-width: 768px) {
                    display: none;
                }
            }
        }

        .totalPriceBox {
            .priceBox {
                display: flex;
                font-weight: 700;
                justify-content: space-between;

                &.wovat {
                    font-weight: 600;
                    font-size: 75%;
                    justify-content: flex-end;
                }
            }
        }
    }

    .mainCta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: .5rem;

        .backWithLogin {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;

            .loginCta {
                svg {
                    width: .6rem;
                }
            }

            .nextContinuation:not(.loginCta) {
                margin-top: 1.5rem;
            }

            .nextContinuation:not(.loginCta) + .nextContinuation {
                margin-top: 0;
            }

        }

        @media (max-width: 499.98px) {
            .btn {
                min-height: 4rem;
                min-width: 13rem;
            }

            .loginCta + .btn {
                min-width: 9rem;
            }
        }

        @media (max-width: 767.98px) {
            .btn.loginCta {
                display: none;
            }
        }

        @media (min-width: 768px) {
            justify-content: flex-end;

            .backWithLogin {
                display: none;
            }
        }
    }

    .asteriskField {
        position: relative;
        top: -.25em;
        color: $color-red;
        margin-left: .25em;
    }

    .thanksSections {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .mt-n2 {
        margin-top: -.75rem !important;
    }
}

#deliveryPriceSummary {
    .smallItem:first-child {
        .master {
            .price {
                font-weight: 700;
                font-size: 1.1rem;
            }
        }
    }
}
