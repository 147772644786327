.section.productCategories {
    .headline {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        a {
            color: $color-gray;
            font-family: $fontFamilyTitles;
            font-weight: 500;
            letter-spacing: .01em;

            &:hover {
                color: $color-green;
            }
        }
    }

    .slider {
        max-width: 100%;

        .slick-track {
            display: flex !important;
        }

        .slick-slide {
            height: inherit !important;
            margin: 0 8px;

            & > div {
                height: 100%;
            }
        }

        .slick-list {
            border-radius: 1rem;
        }
    }

    .categoryList {
        .categoryItem {
            position: relative;
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            text-align: center;
            text-decoration: none;
            padding: 1.5rem 0.75rem;

            &::before {
                position: absolute;
                inset: 0;
                content: "";
                background: $color-lgray;
                border-radius: 12px;
                z-index: -1;
                transition: .25s $defaultTransition;
                will-change: transform;
            }

            img {
                max-width: 70%;
                height: 9rem;
                max-height: 25vw;
                object-fit: contain;
                transition: .4s $defaultTransition;
            }

            .name {
                font-weight: 700;
                color: $color-dblue;
            }

            &:hover {
                &::before {
                    background: $color-lgreen;
                    transform: scale(1.12);
                }

                img {
                    transform: scale(0.9);
                }
            }
        }
    }

    .nav-controls {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        transform: translateY(-50%);
        z-index: 1;
        pointer-events: none;
        margin: 0 calc(12px - 1.5rem);

        .nav-control {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: none;
            border: none;
            width: 3rem;
            height: 3rem;
            transform: scale(.25);
            opacity: 0;
            transition: .4s $defaultTransition;
            z-index: 1;

            svg {
                stroke: $color-dgreen;
            }

            &.enabled {
                pointer-events: all;
                opacity: 1;
                transform: scale(1);
            }

            &:before {
                content: '';
                position: absolute;
                inset: 0;
                background: $color-lgray;
                border: 4px solid #f2f3f5;
                border-radius: 50%;
                transition: .25s $defaultTransition;
                will-change: transform;
                z-index: -1;
            }

            &:hover::before {
                background: $color-lgreen;
                border-color: $color-lgreen;
                border-width: 8px;
                transform: scale(1.1);
            }
        }
    }

    @media (min-width: 768px) {
        .headline {
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

            h2 {
                margin: 0;
            }
        }
    }

    @media (min-width: 1921px) {
        .nav-controls {
            display: none;
        }
    }
}
