.section.eshopCart.eshopCartSummary {
    .bottomSection {
        h1 {
            font-size: 1.875rem;
            font-weight: 700;
        }
    }
    .eshopCartContent {
        padding: 0;
    }

    dl {
        dt {
            color: #8C8E97;
            font-size: .75rem;
            font-weight: 500;
        }
    }

    div[id='div_id_agree'] {
        margin: 2rem 0 0;
    }

    .totalPriceBox {
        display: flex;
        flex-direction: column;
    }
}

#div_id_disable_zbozi {
    display: none;
}
