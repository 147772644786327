.comp__tagPill {
    background: $color-lgray;
    font-size: .75rem;
    color: $color-dgreen;
    font-weight: 400;
    letter-spacing: -.01em;
    line-height: 1.8;
    border-radius: 17px;
    padding: 0 1rem;

    &.purple {
        font-weight: 600;
        color: white;
        background: $color-purple;
    }

    &.orange {
        font-weight: 600;
        color: white;
        background: $color-orange;
    }

    &.gray {
        font-weight: 600;
        color: white;
        background: #adadad;
    }
}
