.section.manufacturersDetail {

    &.breadcrumbs {
        padding-top: 2rem;
    }

    &.content {
        .perex {
            font-size: 1.125rem;
            font-weight: bold;
            line-height: 1.5;

            h1 {
                margin-bottom: 2rem;
            }
        }

        .moreHref[aria-expanded="true"] {
            opacity: 0;
            pointer-events: none;
        }

        .bigDescItem {
            margin-top: -1rem;
        }

        .contentBox {
            &.items {
                margin-top: .75rem;
            }

            &.staticImage {
                img {
                    width: 100%;
                    height: 38rem;
                    max-height: 70vh;
                    object-fit: cover;
                    border-radius: 1rem;
                }
            }

            &.categories {
                .items {
                    display: grid;
                    grid-template-columns: repeat(1, 1fr);
                    gap: .5rem;
                    margin: 1.25rem auto;

                    .item {
                        display: inline-flex;
                        flex-direction: column;
                        position: relative;
                        background: #F3F3F3;
                        border-radius: 15px;
                        color: $color-dblue;
                        text-decoration: none;
                        padding: .75rem 1.5rem;
                        transition: .3s $defaultTransition;

                        .name {
                            max-width: 85%;
                            font-size: 1rem;
                            font-weight: bold;
                        }

                        .counter {
                            font-size: .875rem;
                            color: $color-grayBlue;
                            margin-top: .25rem;
                        }

                        svg {
                            position: absolute;
                            top: 50%;
                            right: 1.5rem;
                            stroke: $color-dblue;
                            transform: translateY(-50%);
                        }

                        &:hover {
                            background: $color-lgreen;
                            transform: scale(1.025);
                        }
                    }
                }

                @media (min-width: 500px) {
                    .items {
                        grid-template-columns: repeat(2, 1fr);
                    }
                }

                @media (min-width: 992px) {
                    .items {
                        grid-template-columns: repeat(3, 1fr);

                        .item {
                            padding: 1rem 2rem;

                            .name {
                                font-size: 1.125rem;
                            }

                            svg {
                                right: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
